<template>
  <router-view />
</template>

<script>
import { provide } from "vue"
import store from "@/store"
import { useRouter, useRoute } from "vue-router"
import firebase from "firebase"

export default {
  setup() {
    //Store implementation
    provide('store', store)

    //Router implementation
    const router = useRouter()
    const route = useRoute()

    //Firebase implementation
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) router.replace("/Login")
      else if (route.path === "/Login" && user) router.replace("/")
    })
  }
};
</script>
<style></style>

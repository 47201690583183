<template>
<div class="container-fluid">
  <div class="row mobile flex-nowrap">
    <SideBar id="desktop" />
    <MobileSideBar id="mobile" />
    <TeamsTable  />
  </div>
</div>
</template>

<script>

import SideBar from '@/components/SideBar.vue'
import MobileSideBar from '@/components/MobileSideBar.vue'
import TeamsTable from '@/components/TeamsTable.vue'

export default {
  name: 'Teams',
  components: {
    SideBar,
    MobileSideBar,
    TeamsTable
  },
}
</script>

<style scoped>
#mobile{
    display: none
  }
#desktop{
  max-width: 100%
}
@media screen and (max-width: 768px){
  #mobile{
    display: flex;
  }
  #desktop{
    display: none
  }
  .mobile{
    display: grid
  }
}
</style>
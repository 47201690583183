<template>
test1
<router-link to="/Test">Test ühte</router-link>
<button v-on:click="nupp()">2</button>
</template>

<script>
import { inject } from 'vue'
import { mapState } from 'vuex'

export default {
  name: 'Testing',
  setup() {
    const store = inject('store')
    return {
      store
    }
  },
  mounted() {
    this.$store.dispatch('loadUsers')
  },
  computed: mapState([
    'users'
  ]),
  methods: {
    nupp() {
      console.log(this.store.state.users)
    }
  },
}
</script>

<template>
  <div class="row-sm-auto bg-dark">
    <div class="d-flex flex-sm-row flex-row align-items-center w-100">
                
      <ul class="nav nav-flush flex-sm-row mb-auto mx-auto justify-content-between w-100 px-3 align-items-center icon">
          <router-link to="/" class="nav-link px-2">
          <li class="nav-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-house" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/><path fill-rule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/></svg>
          </li>
          </router-link>
          
          <router-link to="/Users" class="nav-link py-3 px-2">
            <li class="nav-item">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16"><path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/></svg>
            </li>
          </router-link>

          <router-link to="/Teams" class="nav-link py-3 px-2">
            <li class="nav-item">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16"><path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/><path fill-rule="evenodd" d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"/><path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/></svg>
            </li>
          </router-link>

          <router-link to="/Sims" class="nav-link py-3 px-2">
          <li class="nav-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-sim-fill" viewBox="0 0 16 16"><path d="M5 4.5a.5.5 0 0 1 .5-.5h2v2H5V4.5zM8.5 6V4h2a.5.5 0 0 1 .5.5V6H8.5zM5 7h6v2H5V7zm3.5 3H11v1.5a.5.5 0 0 1-.5.5h-2v-2zm-1 0v2h-2a.5.5 0 0 1-.5-.5V10h2.5z"/><path d="M3.5 0A1.5 1.5 0 0 0 2 1.5v13A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5V3.414a1.5 1.5 0 0 0-.44-1.06L11.647.439A1.5 1.5 0 0 0 10.586 0H3.5zm2 3h5A1.5 1.5 0 0 1 12 4.5v7a1.5 1.5 0 0 1-1.5 1.5h-5A1.5 1.5 0 0 1 4 11.5v-7A1.5 1.5 0 0 1 5.5 3z"/></svg>
          </li>
          </router-link>

          <button class="btn btn-link"><svg @click="Logout()" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-door-open-fill" viewBox="0 0 16 16"> <path d="M1.5 15a.5.5 0 0 0 0 1h13a.5.5 0 0 0 0-1H13V2.5A1.5 1.5 0 0 0 11.5 1H11V.5a.5.5 0 0 0-.57-.495l-7 1A.5.5 0 0 0 3 1.5V15H1.5zM11 2h.5a.5.5 0 0 1 .5.5V15h-1V2zm-2.5 8c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z"/> </svg></button></ul>
        <router-link id="logi" to="/Login"></router-link>

      </div>   
  </div>       
</template>

<script>
// @ is an alias to /src
import firebase from 'firebase'

export default {
  name: 'SideBar',
  setup() {
    const Logout = () => {
      firebase
          .auth()
          .signOut()
          .catch(err => alert(err.message))
    }
    return {
      Logout
    }
  },
  methods: {
    async getName(){
      firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        let email = user.email
        let name = email.split('@')[0].split('.')[0]
        name = name.charAt(0).toUpperCase() + name.slice(1)
        this.name = name
      }
       else {
        const a = document.getElementById("logi")
        a.click()
      }
    })
    }
  }
}
</script>

<style scoped>
</style>
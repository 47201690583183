<template>
<section class="vh-100 bg-dark">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5">
        <div class="card shadow-2-strong" style="border-radius: 1rem;">
          <div class="card-body p-5 text-center">

            <h3 class="mb-5">Sign in to TIW</h3>
            <form @submit.prevent="Login">
            <div class="form-outline mb-4">
              <input type="email" id="typeEmailX" class="form-control form-control-lg" v-model="email" />
              <label class="form-label" for="typeEmailX">Email</label>
            </div>

            <div class="form-outline mb-4">
              <input type="password" id="typePasswordX" class="form-control form-control-lg" v-model="password"/>
              <label class="form-label" for="typePasswordX">Password</label>
            </div>
            <button class="btn btn-primary btn-lg btn-block" type="submit" value="Login">Login</button>
            </form>
            <hr class="my-4">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</template>

<script>
import { ref } from 'vue'
import firebase from 'firebase'

export default {
  setup() {
    const email = ref("")
    const password = ref("")

    const Login = () => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email.value, password.value)
        .then(data => console.log(data))
        .catch(err => alert(err.message));
    }

    return {
      Login,
      email,
      password
    }
  }
  
}
</script>
<template>
asi
</template>

<script>
export default {
  setup() {
    
    console.log(localStorage)
    localStorage.clear()
    console.log(localStorage)
  },
  mounted() {
  }
}
</script>

<template>
  <!--TEAM DETAILS-->
  <Modal v-if="popupTriggers.teamsInfoTrigger" :TogglePopup="() => TogglePopup('teamsInfoTrigger')">
    <div class="mt-2">
      <div class="d-flex justify-content-center">
        <p style="font-weight: bold; font-size: 18px">
          {{ team.name }}
        </p>
      </div>
      <div class="d-flex justify-content-center">
        <button v-on:click="addMembers()" class="btn btn-sm btn-outline-dark btn-rounded">Add members</button>
        <button v-on:click="AddLicenses()" style="margin-left:5px; margin-right:5px" class="btn btn-sm btn-outline-dark btn-rounded">Activate licenses</button>
        <button v-on:click="changeTeam()" class="btn btn-sm btn-outline-dark btn-rounded">Team settings</button>
      </div>
      <table class="table table-hover">
        <thead>
          <tr>
            <th class="bold">Owner name</th>
            <th class="bold">Owner number</th>
            <th class="bold">Contact name</th>
            <th class="bold">Contact email</th>
            <th class="bold">Licenses amount</th>
            <th class="bold">Hunt code</th>
            <th class="bold">License date</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th v-if="team.ownerName != null">{{ team.ownerName }}</th>
            <th v-else>-</th>
            <th v-if="team.phoneNumber != null">{{ team.phoneNumber }}</th>
            <th v-else>-</th>
            <th v-if="team.contactName != null">{{ team.contactName }}</th>
            <th v-else>-</th>
            <th v-if="team.contactEmail != null">{{ team.contactEmail }}</th>
            <th v-else>-</th>
            <th v-if="team.licenceCount != null">{{ team.licenceCount }}</th>
            <th v-else>-</th>
            <th v-if="team.permanentHuntCode != null">{{ team.permanentHuntCode }}</th>
            <th v-else>-</th>
            <th v-if="team.validDate != '' ">{{ team.validDate }}</th>
            <th v-else>-</th>
          </tr>
        </tbody>
      </table>
      <div>
        <table class="table table-hover">
          <thead>
            <tr>
              <th class="bold">#</th>
              <th class="bold">Cellnumber</th>
              <th class="bold">Name</th>
              <th class="bold">Role<button v-on:click="togglePermissions()" class="btn"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16"><path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" /></svg></button></th>
              <th class="bold">Full member<button v-on:click="toggleFullMember()" class="btn"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-pencil-fill" viewBox="0 0 16 16"><path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" /></svg></button></th>
              <th class="bold">License</th>
              <th class="bold">All Groups</th>
              <th class="bold">Details</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(member, index) in team.permissions" :key="member">
              <th>{{ index + 1 }}</th>
              <th v-if="member.user.phoneNumber != null">
                {{ member.user.phoneNumber }}
              </th>
              <th v-else></th>
              <th v-if="member.user.name != null">
                {{ member.user.name }}
              </th>
              <th v-else></th>
              <th v-if="member.permission != null">
                <div v-if="!togglepermissions">
                  {{ member.permission }}
                </div>
                <div v-else>
                  <div v-if="member.permission == 'owner'">
                    {{ member.permission }}
                  </div>
                  <div v-else>
                    {{ member.permission }}
                    <button class="btn" v-on:click="submitPermission(team, member)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-repeat" viewBox="0 0 16 16"><path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/><path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/></svg>
                    </button>
                  </div>
                </div>
              </th>
              <th v-else></th>
              <th>
                <div v-if="!togglefullmembers">
                  {{ member.fullMember }}
                </div>
                <div v-else>
                    {{ member.fullMember }}
                    <button class="btn" v-on:click="submitStatus(team, member)">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-repeat" viewBox="0 0 16 16"><path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" /><path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" /></svg>
                    </button>
                </div>
              </th>

              <th v-if="member.userdata.last_license != null">
                <div v-if="member.userdata.last_license - 2629800000 > datenow"> {{ member.userdata.last_license_date }}</div>
                <div v-else-if="member.userdata.last_license > datenow && member.userdata.last_license - 2629800000 < datenow" style="background-color: yellow">{{ member.userdata.last_license_date }}</div>
                <div v-else style="background-color: red">{{ member.userdata.last_license_date }}</div>
              </th>
              <th v-else></th>

              <th v-if="member.userdata.all_hunts != null">
                {{ member.userdata.all_hunts }}
              </th>
              <th v-else>0</th>

              <th>
                <svg v-on:click="userInfo(member)" xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /><path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/></svg>
              </th>

              <th>
                <div v-if="member.permission == 'owner'"></div>
                <div v-else>
                  <button v-on:click="submitDeleteMember(team, member)" class="btn btn-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" style="margin-bottom: 5px" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16"><path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/><path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/></svg>
                  </button>
                </div>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </Modal>

  <!--USER DETAILS-->
  <Modal v-if="popupTriggers.userInfoTrigger" :TogglePopup="() => TogglePopup('userInfoTrigger')">
    <div>
      <p style="font-weight: bold; font-size: 14px">General Info</p>
      <table class="table table-hover">
        <thead>
          <tr>
            <th class="bold">Name</th>
            <th class="bold">Cellnumber</th>
            <th class="bold">Email</th>
            <th class="bold">Created</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>{{ user.userdata.name }}</th>
            <th>{{ user.userdata.cellnumber }}</th>
            <th>{{ user.userdata.email }}</th>
            <th>{{ user.userdata.created_date }}</th>
          </tr>
        </tbody>
      </table>

      <p style="font-weight: bold; font-size: 14px">Teams Info</p>
      <table class="table table-hover">
        <thead>
          <tr>
            <th class="bold">Team name</th>
            <th class="bold">Role</th>
            <th class="bold">Team owner</th>
            <th class="bold">Team owner number</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in user.userdata.teamsinfo" :key="user">
            <th>{{ user.teamName }}</th>
            <th>{{ user.teamRole }}</th>
            <th>{{ user.teamOwner }}</th>
            <th>{{ user.teamOwnerNumber }}</th>
          </tr>
        </tbody>
      </table>

      <p style="font-weight: bold; font-size: 14px">Group Info</p>
      <table class="table table-hover">
        <thead>
          <tr>
            <th class="bold">Group</th>
            <th class="bold">Info</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th class="bold">Groups created</th>
            <th scope="row">
              {{ user.userdata.created_hunts }}
            </th>
          </tr>
          <tr>
            <th class="bold">Groups joined</th>
            <th scope="row">
              {{ user.userdata.all_hunts }}
            </th>
          </tr>
          <tr>
            <th class="bold">Last group</th>
            <th scope="row">
              <div v-if="user.userdata.last_joined_date == ''">None</div>
              <div v-else>
                {{ user.userdata.last_joined_date }}
              </div>
            </th>
          </tr>
        </tbody>
      </table>

      <div class="d-flex justify-content-between">
        <p style="font-weight: bold; font-size: 14px">Licenses</p>
        <button class="btn btn-outline-dark btn-rounded" v-on:click="toggleUserLicense()">Add License</button>
      </div>
      
      <div v-if="togglenewlicense">
        <p style="font-size: 10px; margin-bottom: -2px">Date</p>
        <input v-model="userLicenseDate" type="date" class="form-control" style="width:60%" />
        <p style="font-size: 10px; margin-bottom: -2px">Comment</p>
        <input id="newLicenseComment" type="text" class="form-control" style="width:60%" />
        <button v-on:click="submitSingleLicense(user)" class="btn btn-outline-dark btn-rounded mt-2">Add License</button>
      </div>
      
      <table class="table table-hover">
        <thead>
          <tr>
            <th class="bold">#</th>
            <th class="bold">Comment</th>
            <th class="bold">Creator</th>
            <th class="bold">Product</th>
            <th class="bold">Created</th>
            <th class="bold">Valid until</th>
          </tr>
        </thead>
        <tbody v-if="!togglelicenses && user.userdata.licenses != null">
          <tr>
            <th>1</th>
            <th>{{ user.userdata.licenses[0].comment }}</th>
            <th>{{ user.userdata.licenses[0].creator }}</th>
            <th>{{ user.userdata.licenses[0].product }}</th>
            <th>{{ new Date(user.userdata.licenses[0].created).getFullYear() }}-{{("0" + (new Date(user.userdata.licenses[0].created).getMonth() + 1)).slice(-2)}}-{{("0" + new Date(user.userdata.licenses[0].created).getDate()).slice(-2)}}</th>
            <th>{{ new Date(user.userdata.licenses[0].validuntil).getFullYear() }}-{{("0" + (new Date(user.userdata.licenses[0].validuntil).getMonth() + 1)).slice(-2)}}-{{("0" + new Date(user.userdata.licenses[0].validuntil).getDate()).slice(-2)}}</th>
          </tr>
        </tbody>
        <tbody v-else-if="togglelicenses">
          <tr v-for="(user, index) in user.userdata.licenses" :key="user">
            <th scope="row">{{ index + 1 }}</th>
            <th scope="row">{{ user.comment }}</th>
            <th scope="row">{{ user.creator }}</th>
            <th scope="row">{{ user.product }}</th>
            <th scope="row">{{ new Date(user.created).getFullYear() }}-{{("0" + (new Date(user.created).getMonth() + 1)).slice(-2)}}-{{ ("0" + new Date(user.created).getDate()).slice(-2) }}</th>
            <th scope="row">{{ new Date(user.validuntil).getFullYear() }}-{{("0" + (new Date(user.validuntil).getMonth() + 1)).slice(-2)}}-{{ ("0" + new Date(user.validuntil).getDate()).slice(-2) }}</th>
          </tr>
        </tbody>
      </table>

      <div class="row justify-content-center" v-if="!togglelicenses">
        <svg v-on:click="toggleLicenses()" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-expand" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8zM7.646.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 1.707V5.5a.5.5 0 0 1-1 0V1.707L6.354 2.854a.5.5 0 1 1-.708-.708l2-2zM8 10a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 14.293V10.5A.5.5 0 0 1 8 10z" /></svg>
      </div>
      <div class="row justify-content-center" v-else-if="togglelicenses">
        <svg v-on:click="toggleLicenses()" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-collapse" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8zm7-8a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 4.293V.5A.5.5 0 0 1 8 0zm-.5 11.707-1.146 1.147a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 11.707V15.5a.5.5 0 0 1-1 0v-3.793z" /></svg>
      </div>


      <p style="font-weight: bold; font-size: 14px">Devices</p>
      <table class="table table-hover">
        <thead>
          <tr>
            <th class="bold">#</th>
            <th class="bold">App Language</th>
            <th class="bold">App Version</th>
            <th class="bold">Created</th>
            <th class="bold">Email</th>
            <th class="bold">Manufacturer</th>
            <th class="bold">Model</th>
            <th class="bold">Name</th>
            <th class="bold">OS</th>
            <th class="bold">OS Version</th>
          </tr>
        </thead>
        <tbody v-if="!toggledevices && user.userdata.devices != null">
          <tr>
            <th>1</th>
            <th>{{ user.userdata.devices[0].applanguage }}</th>
            <th>{{ user.userdata.devices[0].appversion }}</th>
            <th>{{ new Date(user.userdata.devices[0].created).getFullYear() }}-{{("0" + (new Date(user.userdata.devices[0].created).getMonth() + 1)).slice(-2)}}-{{("0" + new Date(user.userdata.devices[0].created).getDate()).slice(-2)}}</th>
            <th>{{ user.userdata.devices[0].email }}</th>
            <th>{{ user.userdata.devices[0].manufacturer }}</th>
            <th>{{ user.userdata.devices[0].model }}</th>
            <th>{{ user.userdata.devices[0].name }}</th>
            <th>{{ user.userdata.devices[0].opsys }}</th>
            <th>{{ user.userdata.devices[0].opsysversion }}</th>
          </tr>
        </tbody>
        <tbody v-else-if="toggledevices">
          <tr v-for="(user, index) in user.userdata.devices" :key="user">
            <th scope="row">{{ index + 1 }}</th>
            <th scope="row">{{ user.applanguage }}</th>
            <th scope="row">{{ user.appversion }}</th>
            <th scope="row">{{ new Date(user.created).getFullYear() }}-{{("0" + (new Date(user.created).getMonth() + 1)).slice(-2)}}-{{("0" + new Date(user.created).getDate()).slice(-2)}}</th>
            <th scope="row">{{ user.email }}</th>
            <th scope="row">{{ user.manufacturer }}</th>
            <th scope="row">{{ user.model }}</th>
            <th scope="row">{{ user.name }}</th>
            <th scope="row">{{ user.opsys }}</th>
            <th scope="row">{{ user.opsysversion }}</th>
          </tr>
        </tbody>
      </table>

      <div class="row justify-content-center" v-if="!toggledevices">
        <svg v-on:click="toggleDevices()" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-expand" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8zM7.646.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 1.707V5.5a.5.5 0 0 1-1 0V1.707L6.354 2.854a.5.5 0 1 1-.708-.708l2-2zM8 10a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 14.293V10.5A.5.5 0 0 1 8 10z" /></svg>
      </div>
      <div class="row justify-content-center" v-else-if="toggledevices">
        <svg v-on:click="toggleDevices()" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-collapse" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8zm7-8a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 4.293V.5A.5.5 0 0 1 8 0zm-.5 11.707-1.146 1.147a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 11.707V15.5a.5.5 0 0 1-1 0v-3.793z" /></svg>
      </div>

      <p style="font-weight: bold; font-size: 14px">Notes</p>
      <table class="table table-hover">
        <thead>
          <tr>
            <th class="bold">#</th>
            <th class="bold">Created</th>
            <th class="bold">Comment</th>
          </tr>
        </thead>
        <tbody v-if="!togglenotes && user.userdata.notes.length != 0">
          <tr>
            <th>1</th>
            <th>{{ new Date(user.userdata.notes[0].created).getFullYear() }}-{{("0" + (new Date(user.userdata.notes[0].created).getMonth() + 1)).slice(-2)}}-{{("0" + new Date(user.userdata.notes[0].created).getDate()).slice(-2)}}</th>
            <th v-if="user.userdata.notes[0].data.text != null">{{ user.userdata.notes[0].data.text }}</th>
            <th scope="row" v-else-if="user.userdata.notes[0].data.text == null && user.userdata.notes[0].name == 'huntloc_login'">Huntloc Login</th>
            <th scope="row" v-else-if="user.userdata.notes[0].data.text == null && user.userdata.notes[0].name == 'liveteam_login'">Liveteam Login</th>
            <th scope="row" v-else></th>
          </tr>
        </tbody>
        <tbody v-else-if="togglenotes">
          <tr v-for="(notes, index) in user.userdata.notes" :key="notes">
            <th scope="row">{{ index + 1 }}</th>
            <th>{{ new Date(notes.created).getFullYear() }}-{{("0" + (new Date(notes.created).getMonth() + 1)).slice(-2)}}-{{("0" + new Date(notes.created).getDate()).slice(-2)}}</th>
            <th scope="row" v-if="notes.data.text != null">{{ notes.data.text }}</th>
            <th scope="row" v-else-if="notes.data.text == null && notes.name == 'huntloc_login'">Huntloc Login</th>
            <th scope="row" v-else-if="notes.data.text == null && notes.name == 'liveteam_login'">Liveteam Login</th>
            <th scope="row" v-else></th>
          </tr>
        </tbody>
      </table>

      <div class="row justify-content-center" v-if="!togglenotes">
        <svg v-on:click="toggleNotes()" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-expand" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8zM7.646.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 1.707V5.5a.5.5 0 0 1-1 0V1.707L6.354 2.854a.5.5 0 1 1-.708-.708l2-2zM8 10a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 14.293V10.5A.5.5 0 0 1 8 10z" /></svg>
      </div>
      <div class="row justify-content-center" v-else-if="togglenotes">
        <svg v-on:click="toggleNotes()" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-collapse" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8zm7-8a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 4.293V.5A.5.5 0 0 1 8 0zm-.5 11.707-1.146 1.147a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 11.707V15.5a.5.5 0 0 1-1 0v-3.793z" /></svg>
      </div>

      <p style="font-weight: bold; font-size: 14px">Add note</p>
      <textarea id="userNoteInput" style="height: 100px" class="form-control" />
      <button type="button" class="btn btn-outline-dark btn-rounded" data-mdb-ripple-color="dark" v-on:click="addNotes(user)">Add Note</button>

      <div class="justify-content-between d-flex"><span><p style="font-weight: bold; font-size: 14px">Portal Authentication</p></span><span><button class="btn btn-outline-dark btn-rounded" v-on:click="toPortal()">Portal</button></span></div>
      <input type="text" class="form-control" placeholder="" v-model="portalPIN"/>
      <button type="button" class="btn btn-outline-dark btn-rounded" data-mdb-ripple-color="dark" v-on:click="loginPortal(user)">Login</button>
    </div>
  </Modal>

  <!--NEW TEAM-->
  <Modal v-if="popupTriggers.addNewTeamTrigger" :TogglePopup="() => TogglePopup('addNewTeamTrigger')">
    <div style="width: 400px">
      <h5>Create Team</h5>
      <p>Owner number</p>
      <input id="teamOwnerInput" class="search form-control form-control-sm mb-2" type="text" placeholder="Cellnumber" />
      <p>Team name</p>
      <input id="teamNameInput" class="search form-control form-control-sm mb-2" type="text" placeholder="Name" />
      <button v-on:click="submitNewTeam()" class="btn btn-sm btn-outline-dark btn-rounded">
        Create team
      </button>
    </div>
  </Modal>

  <!--TEAM SETTINGS-->
  <Modal v-if="popupTriggers.changeTeamTrigger" :TogglePopup="() => TogglePopup('changeTeamTrigger')">
    <h5>Team Settings</h5>
    <p>Team uuid: {{team.uuid}}</p>
    <p>Team name</p>
    <div class="d-flex">
      <input id="newTeamNameInput" class="form-control" placeholder="New team name"/>
      <button v-on:click="submitNewTeamName(team)" class="btn btn-sm btn-outline-dark btn-rounded">Submit</button>
    </div>
    <p>Contact name</p>
    <div class="d-flex">
      <input id="newContactNameInput" class="form-control" placeholder="New contact name"/>
      <button v-on:click="submitNewContactName(team)" class="btn btn-sm btn-outline-dark btn-rounded">Submit</button>
    </div>
    <p>Contact email</p>
    <div class="d-flex">
      <input id="newContactEmailInput" class="form-control" placeholder="New contact email"/>
      <button v-on:click="submitNewContactEmail(team)" class="btn btn-sm btn-outline-dark btn-rounded">Submit</button>
    </div>
    <p>Licenses amount</p>
    <div class="d-flex">
      <input id="newLicenseAmountInput" class="form-control" placeholder="New licenses amount"/>
      <button v-on:click="submitNewLicenseAmount(team)" class="btn btn-sm btn-outline-dark btn-rounded">Submit</button>
    </div>
    <p>License date</p>
    <div class="d-flex">
      <input v-model="teamLicenseDate" type="date" class="form-control" />
      <button v-on:click="submitNewLicenseDate(team)" class="btn btn-sm btn-outline-dark btn-rounded">Submit</button>
    </div>
    <p>Hunt code</p>
    <div class="d-flex">
      <input id="newHuntCodeInput" class="form-control" placeholder="New hunt code"/>
      <button v-on:click="submitNewHuntCode(team)" class="btn btn-sm btn-outline-dark btn-rounded">Submit</button>
    </div>
    <p>Delete team</p>
    <div class="d-flex">
    <button v-on:click="submitDeleteTeam(team)" class="btn btn-sm btn-outline-dark btn-rounded">Delete</button>
    </div>
  </Modal>


  <!--ADD MEMBERS-->
  <Modal
    v-if="popupTriggers.addMembersTrigger"
    :TogglePopup="() => TogglePopup('addMembersTrigger')"
  >
    <div>
      <h5>Adding members</h5>
      <p>Phonenumber;view/admin;Member(true,false)</p>
        <textarea
        id="newMemberInput"
        placeholder="..."
        style="width: 400px; height: 200px"
      ></textarea
      ><br>
      <button
        v-on:click="submitNewMembers(team)"
        class="btn btn-sm btn-outline-dark btn-rounded"
      >
        Add members
      </button>
    </div>
  </Modal>

  <!--ADD LICENSE-->
  <Modal v-if="popupTriggers.activateLicenseTrigger" :TogglePopup="() => TogglePopup('activateLicenseTrigger')">
    <div style="width:400px">
      <div class="d-flex"><p style="font-weight: bold; font-size: 14px">Team Licenses</p>
        <div style="font-size: 10px">
          <p style="margin-left:5px">
            <span style="height:7px;width:7px;background-color:salmon;border-radius:50%;display:inline-block"></span>  Expired<br>
            <span style="height:7px;width:7px;background-color:yellow;border-radius:50%;display:inline-block"></span>  Expires in a month<br>
            <span style="height:7px;width:7px;background-color:orange;border-radius:50%;display:inline-block"></span>  Expires before team
          </p>
        </div>
      </div>
      <p>Date: <b>{{ team.validDate }}</b></p>
      <p>Count: <b>{{team.licenceCount}}</b></p>
      <div class="d-flex">
        <span><p>Comment:<span style="width: 2px"></span> </p></span>
        <span><input id="licenseCommentInput" class="form-control" type="text" placeholder="Comment"></span>
      </div>
      <div class="d-flex justify-content-between">
        <span><button v-on:click="toggleAddChoice('all')" class="btn btn-sm btn-outline-dark btn-rounded">All</button></span>
        <span><button v-on:click="toggleAddChoice('needed')" class="btn btn-sm btn-outline-dark btn-rounded">Needed</button></span>
        <span><button v-on:click="toggleAddChoice('')" class="btn btn-sm btn-outline-dark btn-rounded">Custom</button></span>
        <span><button v-on:click="activateTeamLicense(team)" class="btn btn-lg btn-outline-primary btn-rounded">Submit</button></span>
      </div>
      <div v-if="addlicenseschoice == 'all'">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Number</th>
              <th>Name</th>
              <th>Licence</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="member in team.permissions" :key="member">
              <th v-if="member.fullMember">{{member.user.phoneNumber}}</th>
              <th v-if="member.fullMember">{{member.user.name}}</th>
              <th v-if="member.fullMember">
                <div v-if="member.userdata.last_license < new Date().getTime()" style="background-color:red">{{member.userdata.last_license_date}}</div>
                <div v-else-if="member.userdata.last_license < (new Date().getTime() + 2629800000)" style="background-color:yellow">{{member.userdata.last_license_date}}</div>
                <div v-else-if="member.userdata.last_license < team.validUntil" style="background-color:orange">{{member.userdata.last_license_date}}</div>
                <div v-else>{{member.userdata.last_license_date}}</div>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="addlicenseschoice == 'needed'">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Number</th>
              <th>Name</th>
              <th>Licence</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="member in team.permissions" :key="member">
              <th v-if="member.fullMember && member.userdata.last_license < team.validUntil">{{member.user.phoneNumber}}</th>
              <th v-if="member.fullMember && member.userdata.last_license < team.validUntil">{{member.user.name}}</th>
              <th v-if="member.fullMember && member.userdata.last_license < team.validUntil">
                <div v-if="member.userdata.last_license < new Date().getTime()" style="background-color:red">{{member.userdata.last_license_date}}</div>
                <div v-else-if="member.userdata.last_license < (new Date().getTime() + 2629800000)" style="background-color:yellow">{{member.userdata.last_license_date}}</div>
                <div v-else-if="member.userdata.last_license < team.validUntil" style="background-color:orange">{{member.userdata.last_license_date}}</div>
                <div v-else>{{member.userdata.last_license_date}}</div>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Number</th>
              <th>Name</th>
              <th>Full member</th>
              <th>Licence</th>
              <th>Choice</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="member in team.permissions" :key="member">
              <th>{{member.user.phoneNumber}}</th>
              <th>{{member.user.name}}</th>
              <th v-if="member.fullMember">
                <div v-if="member.fullMember">Yes</div>
                <div v-else>No</div>
              </th>
              <th>
                <div v-if="member.userdata.last_license < new Date().getTime()" style="background-color:red">{{member.userdata.last_license_date}}</div>
                <div v-else-if="member.userdata.last_license < (new Date().getTime() + 2629800000)" style="background-color:yellow">{{member.userdata.last_license_date}}</div>
                <div v-else-if="member.userdata.last_license < team.validUntil" style="background-color:orange">{{member.userdata.last_license_date}}</div>
                <div v-else>{{member.userdata.last_license_date}}</div>
              </th>
              <th><input class="checkbox" type="checkbox" v-on:click="AddCustomMember(member)"></th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </Modal>


<!--MAIN-->
  <div class="col bg-light" style="width: 100%" v-if="loaded == false">
    <div class="lds-ring" style="margin-left: 50%; margin-top: 20%">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <div v-else class="col bg-light">
    <div class="d-flex justify-content-between mt-3">
    <div class="d-flex">
      <div class="d-flex" style="font-size: 12px">
        <p class="pt-1">Entries:</p>
        <select v-model="currentEntries" class="form-control form-control-sm mb-3" style="font-weight: bold" v-on:change="paginateEntries">
          <option v-for="se in showEntries" :key="se" :value="se">{{ se }}</option>
        </select>
      </div>
      <div style="font-size: 10px">
        <p style="margin-left:5px">
          <span style="height:7px;width:7px;background-color:salmon;border-radius:50%;display:inline-block"></span>  Expired<br>
          <span style="height:7px;width:7px;background-color:yellow;border-radius:50%;display:inline-block"></span>  Expires in a month<br>
        </p>
      </div>
      <div class="d-flex">
        <p class="me-3 d-flex">Updated: {{ last_updated }}  <div v-if="!store.state.usersLoaded" class="d-flex">
            <div class="lds-ring-sm">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              </div></div>
        </p>
      </div>
    </div>
      <div class="d-flex justify-content-between">
        <div>
          <span><button class="btn btn-sm mx-2 btn-outline-dark btn-rounded" v-on:click="resetTeams()">Reset filters</button></span>
          <span><button class="btn btn-sm mx-2 btn-outline-dark btn-rounded" v-on:click="addNewTeam()">Add team</button></span>
        </div>
        <span><input class="search form-control form-control-sm me3 pt-2" type="text" placeholder="Search.." v-model="searchInput" v-on:keyup="searchEvent"/></span>
      </div>
    </div>


    <table id="table" class="table table-hover table-bordered overflow-auto">
      <thead>
        <tr class="h6">
          <th>
            <div class="d-flex justify-content-between">
              <span>Cellnumber</span>
              <span v-if="sortnumber == 'default'"><button class="btn" v-on:click.prevent="sortByColumn('phoneNumber')"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-down-up" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"/></svg></button></span>
              <span v-else-if="sortnumber == 'desc'"><button class="btn" v-on:click.prevent="sortByColumn('phoneNumber')"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-up" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/></svg></button></span>
              <span v-else-if="sortnumber == 'asc'"><button class="btn" v-on:click.prevent="sortByColumn('phoneNumber')"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/></svg></button></span>
            </div>
          </th>
          <th>
            <div class="d-flex justify-content-between">
              <span>Name</span>
              <span v-if="sortownername == 'default'"><button class="btn" v-on:click.prevent="sortByColumn('ownerName')"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-down-up" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"/></svg></button></span>
              <span v-else-if="sortownername == 'desc'"><button class="btn" v-on:click.prevent="sortByColumn('ownerName')"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-up" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/></svg></button></span>
              <span v-else-if="sortownername == 'asc'"><button class="btn" v-on:click.prevent="sortByColumn('ownerName')"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/></svg></button></span>
            </div>
          </th>
          <th>
            <div class="d-flex justify-content-between">
              <span>Team Name</span>
              <span v-if="sortteamname == 'default'"><button class="btn" v-on:click.prevent="sortByColumn('teamName')"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-down-up" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"/></svg></button></span>
              <span v-else-if="sortteamname == 'desc'"><button class="btn" v-on:click.prevent="sortByColumn('teamName')"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-up" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/></svg></button></span>
              <span v-else-if="sortteamname == 'asc'"><button class="btn" v-on:click.prevent="sortByColumn('teamName')"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/></svg></button></span>
            </div>
          </th>
          <th>
            <div class="d-flex justify-content-between">
              <span>Full Members</span>
              <span v-if="sortfullmembers == 'default'"><button class="btn" v-on:click.prevent="sortByColumn('fullmembers')"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-down-up" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"/></svg></button></span>
              <span v-else-if="sortfullmembers == 'desc'"><button class="btn" v-on:click.prevent="sortByColumn('fullmembers')"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-up" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/></svg></button></span>
              <span v-else-if="sortfullmembers == 'asc'"><button class="btn" v-on:click.prevent="sortByColumn('fullmembers')"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/></svg></button></span>
            </div>
          </th>
          <th>
            <div class="d-flex justify-content-between">
              <span>Other Members</span>
              <span v-if="sortothermembers == 'default'"><button class="btn" v-on:click.prevent="sortByColumn('othermembers')"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-down-up" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"/></svg></button></span>
              <span v-else-if="sortothermembers == 'desc'"><button class="btn" v-on:click.prevent="sortByColumn('othermembers')"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-up" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/></svg></button></span>
              <span v-else-if="sortothermembers == 'asc'"><button class="btn" v-on:click.prevent="sortByColumn('othermembers')"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/></svg></button></span>
            </div>
          </th>
          <th>
            <div class="d-flex justify-content-between">
              <span>Licenses</span>
                <span v-if="sortlicensescount == 'default'"><button class="btn" v-on:click.prevent="sortByColumn('licenceCount')"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-down-up" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"/></svg></button></span>
                <span v-else-if="sortlicensescount == 'desc'"><button class="btn" v-on:click.prevent="sortByColumn('licenceCount')"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-up" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/></svg></button></span>
                <span v-else-if="sortlicensescount == 'asc'"><button class="btn" v-on:click.prevent="sortByColumn('licenceCount')"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/></svg></button></span>
              </div>
          </th>
          <th>
            <div class="d-flex justify-content-between">
              <span>Valid until</span>
              <span v-if="sortvaliduntil == 'default'"><button class="btn" v-on:click.prevent="sortByColumn('validUntil')"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-down-up" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"/></svg></button></span>
              <span v-else-if="sortvaliduntil == 'desc'"><button class="btn" v-on:click.prevent="sortByColumn('validUntil')"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-up" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/></svg></button></span>
              <span v-else-if="sortvaliduntil == 'asc'"><button class="btn" v-on:click.prevent="sortByColumn('validUntil')"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-down" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"/></svg></button></span>
            </div>
          </th>
          <th>
            <div class="d-flex justify-content-between">
              <span>Details</span>
            </div>
          </th>
        </tr>

        <tr>
          <th>
            <input type="search" class="form-control form-control-sm search" placeholder="" aria-label="Name" v-model="col.phoneNumber" v-on:keyup="filterByColumn"/>
          </th>
          <th>
            <input type="search" class="form-control form-control-sm search" placeholder="" aria-label="Name" v-model="col.ownerName" v-on:keyup="filterByColumn"/>
          </th>
          <th>
            <input type="search" class="form-control form-control-sm search" placeholder="" aria-label="Name" v-model="col.teamName" v-on:keyup="filterByColumn"/>
          </th>
          <th>
            <div class="numberSearch">
              <input type="search" class="form-control form-control-sm" v-model="col.fullmembersmin" @keyup="filterByColumn"/>-
              <input type="search" class="form-control form-control-sm" v-model="col.fullmembersmax" @keyup="filterByColumn"/>
            </div>
          </th>
          <th>
            <div class="numberSearch">
              <input type="search" class="form-control form-control-sm" v-model="col.othermembersmin" @keyup="filterByColumn"/>-
              <input type="search" class="form-control form-control-sm" v-model="col.othermembersmax" @keyup="filterByColumn"/>
            </div>
          </th>
          <th>
            <div class="numberSearch">
              <input type="search" class="form-control form-control-sm" v-model="col.licenceCountmin" @keyup="filterByColumn"/>-
              <input type="search" class="form-control form-control-sm" v-model="col.licenceCountmax" @keyup="filterByColumn"/>
            </div>
          </th>
          <th>
            <div class="numberSearch">
              <input type="search" class="form-control form-control-sm" v-model="col.validUntilmin" @keyup="filterByColumn"/>-
              <input type="search" class="form-control form-control-sm" v-model="col.validUntilmax" @keyup="filterByColumn"/>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="td in tableData" :key="td">
          <td>{{ td.phoneNumber }}</td>
          <td>{{ td.ownerName }}</td>
          <td>{{ td.teamName }}</td>
          <td>{{ td.fullmembers }}</td>
          <td>{{ td.othermembers }}</td>
          <td>{{ td.licenceCount }}</td>
          <td>
            <div v-if="td.validDate < new Date().getTime()" class="background-color:salmon">{{ td.validDate }}</div>
            <div v-else-if="td.validDate < new Date().getTime() + 2629800000" class="background-color:yellow">{{ td.validDate }}</div>
            <div v-else>{{ td.validDate }}</div>
          </td>
          <td class="d-flex justify-content-center">
            <svg v-on:click="teamsInfo(td)" xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/></svg>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="d-flex d-flex justify-content-between mb-1">
      <div style="font-size: 12px">Show {{ showInfo.from }} to {{ showInfo.to }} of {{ showInfo.of }} entries</div>
      <div style="font-size: 10px">
        <ul class="list-group list-group-horizontal mb-1">
          <li :class="['list-group-item border px-2', { disabled: currentPage === 1 },]">
            <a href="#" class="nav-link" v-on:click.prevent="(currentPage = 1), paginateEntries()">First</a>
          </li>
          <li :class="['list-group-item border px-2', { disabled: currentPage === 1 },]">
            <a href="#" class="nav-link" @click.prevent="currentPage < 1 ? (currentPage = 1) : (currentPage -= 1), paginateEntries()">Prev</a>
          </li>
          <li v-for="pagi in showPagination" :key="pagi" :class="['list-group-item border px-2', { ellipsis: pagi === '...', active: pagi === currentPage },]">
            <a href="#" class="nav-link bg-white text-dark" v-on:click.prevent="paginateEvent(pagi)">{{ pagi }}</a>
          </li>
          <li :class="['list-group-item border px-2', { disabled: currentPage === allPages },]">
            <a href="#" class="nav-link" v-on:click.prevent="currentPage > allPages ? (currentPage = allPages) : (currentPage += 1), paginateEntries()">Next</a>
          </li>
          <li :class="['list-group-item border px-2', { disabled: currentPage === allPages },]">
            <a href="#" class="nav-link" v-on:click.prevent="(currentPage = allPages), paginateEntries()">Last</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import * as $ from "alga-js"
import { ref } from "vue"
import Modal from "@/components/Modal.vue"
import firebase from "firebase"
import { inject } from 'vue'
import { mapState } from 'vuex'

export default {
  name: "TeamsTable",
  components: {
    Modal,
  },
  setup() {
    const store = inject('store')
    const popupTriggers = ref({
      teamInfoTrigger: false,
      userInfoTrigger: false,
      addNewTeamTrigger: false,
      changeTeamTrigger: false,
      addMembersTrigger: false,
      activateLicenseTrigger: false,
    })
    const TogglePopup = (trigger) => {
      popupTriggers.value[trigger] = !popupTriggers.value[trigger]
    };
    return {
      Modal,
      popupTriggers,
      TogglePopup,
      store
    };
  },
  mounted() {
    if(!this.store.state.usersLoaded){
      this.$store.dispatch('loadUsers')
    }
  },
  data() {
    //SORTEERIMINE JA FILTREERIMINE JÄI POOLELI
    return {
      //SORT ICONS
      sortnumber: "default",
      sortownername: "default",
      sortteamname: "default",
      sortfullmembers: "default",
      sortothermembers: "default",
      sortlicensescount: "default",
      sortvaliduntil: "default",
      //TOGGLES
      togglelicenses: false,
      toggledevices: false,
      togglenotes: false,
      togglepermissions: false,
      togglefullmembers: false,
      togglenewlicense: false,
      //OTHER
      datenow: new Date().getTime(),
      datemonth: new Date().getTime() + 2629800000,
      title: "Teams",
      portalPIN: "",
      usersLoaded: false,
      loaded: false,
      permission: "",
      userLicenseDate: 0,
      last_updated: 0,
      columns: [
        { name: "phoneNumber", text: "Cellnumber" },
        { name: "ownerName", text: "Name" },
        { name: "teamName", text: "Team Name" },
        { name: "fullmembers", text: "Full Members" },
        { name: "othermembers", text: "Other Members" },
        { name: "licenceCount", text: "Licenses" },
        { name: "validUntil", text: "validUntil"},
        { name: "details", text: "Details" },
      ],
      entries: [],
      showEntries: [1, 5, 10, 15, 25, 50, 100, 500],
      currentEntries: 50,
      filteredEntries: [],
      currentPage: 1,
      allPages: 1,
      searchInput: "",
      searchEntries: [],
      addlicenseschoice: "all",
      customlicense: [],
      col: {
        phoneNumber: "",
        ownerName: "",
        teamName: "",
        fullmembers: "",
        othermembers: "",
        licenceCount: "",
        validUntil: ""
      },
      sortcol: {
        phoneNumber: "",
        ownerName: "",
        teamName: "",
        fullmembers: "",
        othermembers: "",
        licenceCount: "",
        validUntil: ""
      },
    };
  },
  computed: {
    showInfo() {
      return $.array.pageInfo(this.getCurrentEntries(), this.currentPage, this.currentEntries)
    },
    showPagination() {
      return $.array.pagination(this.allPages, this.currentPage, 3)
    },
    tableColumns() {
      return this.columns
    },
    tableData() {
      return this.filteredEntries
    },
    computed: mapState(['users']),
  },
  created() {
    this.getTeams()
  },
  methods: {
    //GET REQUESTS
    //BASE GET API for Teams
    async getTeams() {
      let teamAPI = "http://tiw.traxby.com/api/team/list_all"
      axios.get(teamAPI).then((res) => {
        this.entries = res.data
        for (let i = 0; i < this.entries.length; i++) {
          this.entries[i].licenceCountmin = this.entries[i].licenceCount
          this.entries[i].licenceCountmax = this.entries[i].licenceCount
          
          //Valid until to date format
          this.entries[i].validDate = ""
          this.entries[i].validUntilmin = this.entries[i].validUntil
          this.entries[i].validUntilmax = this.entries[i].validUntil
          if(this.entries[i].validUntil != 0 && this.entries[i].validUntil != null){
            let date = this.entries[i].validUntil
            let y = new Date(date).getFullYear()
            let m = ("0" + (new Date(date).getMonth() + 1)).slice(-2)
            let d = ("0" + (new Date(date).getDate())).slice(-2)
            this.entries[i].validDate = y+"-"+m+"-"+d
          }

          //Other information
          this.entries[i].phoneNumber = ""
          this.entries[i].ownerName = ""
          this.entries[i].teamName = ""
          this.entries[i].members = 0
          if(this.entries[i].permissions.length != 0 || this.entries[i].permissions.length != null) {
            this.entries[i].members = this.entries[i].permissions.length
            this.entries[i].fullmembers = 0
            this.entries[i].othermembers = 0
            for (let j = 0; j < this.entries[i].permissions.length; j++) {
              if(this.entries[i].permissions[j].fullMember == true) this.entries[i].fullmembers++
              else this.entries[i].othermembers++
              this.entries[i].permissions[j].userdata = []
              if (this.entries[i].permissions[j].permission == "owner") {
                this.entries[i].phoneNumber = this.entries[i].permissions[j].user.phoneNumber
                this.entries[i].ownerName = this.entries[i].permissions[j].user.name
              }
            }
            if (this.entries[i].name != null) this.entries[i].teamName = this.entries[i].name
            this.entries[i].fullmembersmax = this.entries[i].fullmembers
            this.entries[i].fullmembersmin = this.entries[i].fullmembers
            this.entries[i].othermembersmax = this.entries[i].othermembers
            this.entries[i].othermembersmin = this.entries[i].othermembers
          }
        }
        console.log(this.entries)
        this.filterentries = this.entries
        this.defaultentries = this.entries
        this.paginateData(this.entries)
        this.allPages = $.array.pages(this.entries, this.currentEntries)
        this.loaded = true
        this.last_updated = localStorage.getItem('last_updated')
      })
    },

    //TEAM DETAILS SCREEN INFO
    teamsInfo(team) {
      this.users = this.store.state.users
      this.team = team
      //Sync users to team members
      for (let i = 0; i < this.team.permissions.length; i++) {
        for (let j = 0; j < this.users.length; j++) {
          if (this.team.permissions[i].user.phoneNumber ===this.users[j].cellnumber) {
            this.team.permissions[i].userdata = this.users[j]
            break
          }
        }
        let cellnumber = this.team.permissions[i].user.phoneNumber
        for (let j = 0; j < this.entries.length; j++){
          let team = this.entries[j]
          for (let k = 0; k < team.permissions.length; k++){
            let member = team.permissions[k]
            if(member.user.phoneNumber === cellnumber){
              let teamname = team.teamName
              let role = member.permission
              let name = team.ownerName
              let number = team.phoneNumber
              this.team.permissions[i].userdata.teamsinfo.push({
                teamName: teamname,
                teamRole: role,
                teamOwner: name,
                teamOwnerNumber: number
              })
            }
          }
        }
      }
      console.log(this.team)
      this.teamTrigger()
    },

    //USER DETAILS SCREEN INFO
    userInfo(user) {
      this.user = user
      let date = this.user.userdata.created
      let y = new Date(date).getFullYear()
      let m = ("0" + (new Date(date).getMonth() + 1)).slice(-2)
      let d = ("0" + new Date(date).getDate()).slice(-2)
      this.user.userdata.created_date = y + "-" + m + "-" + d
      this.user.userdata.last_joined_date = ""
      if(this.user.userdata.last_joined != null){
        date = this.user.userdata.last_joined
        y = new Date(date).getFullYear()
        m = ("0" + (new Date(date).getMonth() + 1)).slice(-2)
        d = ("0" + new Date(date).getDate()).slice(-2)
        this.user.userdata.last_joined_date = y + "-" + m + "-" + d
      }
     
      let notearraylength = 0
      let apiURL = "http://tiw.traxby.com/api/activity/" + user.userdata.cellnumber
      axios.get(apiURL).then((res) => {
        this.user.userdata.notes = res.data
        notearraylength = this.user.userdata.notes.length
        this.user.userdata.notesarraylength = notearraylength
        this.userTrigger()
      })
      console.log(this.user)
    },

    //TOGGLES__________________________________________________________________________
    toggleDevices() {
      this.toggledevices = !this.toggledevices
    },
    toggleLicenses() {
      this.togglelicenses = !this.togglelicenses
    },
    toggleNotes() {
      this.togglenotes = !this.togglenotes
    },
    togglePermissions() {
      this.togglepermissions = !this.togglepermissions
    },
    toggleFullMember() {
      this.togglefullmembers = !this.togglefullmembers
    },
    toggleUserLicense() {
      this.togglenewlicense = !this.togglenewlicense
      let def = Date.now() + 31556952000
      let y = new Date(def).getFullYear()
      let m = ("0" + (new Date(def).getMonth() + 1)).slice(-2)
      let d = ("0" + (new Date(def).getDate())).slice(-2)
      this.userLicenseDate = y+"-"+m+"-"+d
    },
    toggleAddChoice(option) {
      this.addlicenseschoice = option
    },
    //TRIGGERS_______________________________________________________________
    addNewTeam() {
      this.TogglePopup("addNewTeamTrigger")
      setTimeout(() => {}, 1)
    },
    changeTeam() {
      let def = Date.now() + 31556952000
      let y = new Date(def).getFullYear()
      let m = ("0" + (new Date(def).getMonth() + 1)).slice(-2)
      let d = ("0" + (new Date(def).getDate())).slice(-2)
      this.teamLicenseDate = y+"-"+m+"-"+d
      this.TogglePopup("changeTeamTrigger")
      setTimeout(() => {}, 1)
    },
    addMembers() {
      this.TogglePopup("addMembersTrigger")
      setTimeout(() => {}, 1)
    },
    AddLicenses() {
      this.TogglePopup("activateLicenseTrigger")
      setTimeout(() => {}, 1)
    },
    userTrigger() {
      this.TogglePopup("userInfoTrigger")
      setTimeout(() => {}, 1)
    },
    teamTrigger() {
      this.TogglePopup("teamsInfoTrigger")
      setTimeout(() => {}, 1)
    },


    //POST REQUESTS_________________________________________________________________
    //BASE TEAM UPLOAD
    submitNewTeam() {
      let name = document.getElementById("teamNameInput")
      let cellnumber = document.getElementById("teamOwnerInput")
      if (name.value == "" || cellnumber.value == "") return alert("No number or name given!")
      else {
        let backendAPI = "http://tiw.traxby.com/api/team/" + cellnumber.value
        let body = { name: name.value }
        axios.post(backendAPI, body).then((res) => {
          console.log(res)
          let newTeam = res.data
          newTeam.othermembers = 1
          newTeam.othermembersmin = 1
          newTeam.othermembersmax = 1
          newTeam.fullmembers = 0
          newTeam.fullmembersmin = 0
          newTeam.fullmembersmax = 0
          newTeam.teamName = newTeam.name
          newTeam.ownerName = newTeam.permissions[0].user.name
          newTeam.phoneNumber = newTeam.permissions[0].user.phoneNumber
          this.entries.push(newTeam)
          this.filterentries = this.entries
          this.defaultentries = this.entries
          this.paginateData(this.entries)
          this.allPages = $.array.pages(this.entries, this.currentEntries)
          alert("Team "+newTeam.teamName+" has been added")
          this.addNewTeam()
        })
      }
    },
    //POST NEW MEMBERS
    submitNewMembers(team) {
      let text = document.getElementById("newMemberInput")
      let lines = text.value.split("\n")
      for (let i = 0; i < lines.length; i++) {
        if(lines[i].length > 1){
          let number = lines[i].split(";")[0]
          let permission = lines[i].split(";")[1]
          let status = lines[i].split(";")[2]
          let member
          if(status == "true") member = true
          else member = false
          let body = {
            user: {
              phoneNumber: number,
            },
            permission: permission,
            fullMember: member
          }
          let backendAPI = "http://tiw.traxby.com/api/team/" + team.uuid + "/permissions/" + team.phoneNumber
          axios.post(backendAPI, body).then((res) => {
            console.log(res)
            let member = res.data
            if(status == "true"){
              team.fullmembers += 1
              team.fullmembersmin += 1
              team.fullmembersmax += 1
            }
            else{
              team.othermembers += 1
              team.othermembersmin += 1
              team.othermembersmax += 1
            }
            for(let i = 0; i < this.users.length; i++){
              if(this.users[i].cellnumber == number){
                member.userdata = this.users[i]
              }
            }
            team.permissions.push(member)
          })
          this.filterentries = this.entries
          this.defaultentries = this.entries
          this.paginateData(this.entries)
          this.allPages = $.array.pages(this.entries, this.currentEntries)
        }
      }
      alert("Members have been added")
      this.addMembers()
    },
    //TODO
    submitSingleLicense(user) {
      let now = Date.now()
      let email = firebase.auth().currentUser.email
      let name = email.split('@')[0].split('.')[0]
      name = name.charAt(0).toUpperCase() + name.slice(1)
      let comment = document.getElementById("newLicenseComment")
      let time = new Date(this.userLicenseDate)
      let body = {
        created : now,
        creator : name,
        comment : comment.value,
        valid : true,
        validUntil : time.getTime(),
        phoneNumber : user.userdata.cellnumber
      }
      console.log(body)
      let backendAPI = "http://tiw.traxby.com/api/rest/lic"
      axios.post(backendAPI, body).then((res) => {
        body.validuntil = body.validUntil
        console.log(body)
        console.log(res)
        user.userdata.licenses.push(body)
        alert("License added to "+user.userdata.cellnumber+" until "+this.userLicenseDate)
        this.togglenewlicense = !this.togglenewlicense
      })
    },
    addNotes(user) {
      let note = document.getElementById("userNoteInput")
      if (note.value != "") {
        let body = {
          name: "admin_note",
          created: Date.now(),
          data: { text: note.value },
          dataJsonStr: null,
        }
        let apiURL = "http://tiw.traxby.com/api/activity/" + user.userdata.cellnumber
        axios.post(apiURL, body).then((res) => {
          console.log(res)
          alert("Note has been added")
          document.getElementById("userNoteInput").value = ""
          user.userdata.notes.push(body)
          console.log(user.userdata.notes)
        })
      }
    },

    //Adding Licenses To Team Members
    activateTeamLicense(team) {
      //Basic Information
      let comment = document.getElementById("licenseCommentInput")
      let now = Date.now()
      let email = firebase.auth().currentUser.email
      let name = email.split('@')[0].split('.')[0]
      name = name.charAt(0).toUpperCase() + name.slice(1)
      console.log(team)

      //What Members
      let licenseTeam = []
      if (this.addlicenseschoice == "all"){
        for (let i = 0; i < team.permissions.length; i++){
          let member = team.permissions[i]
          if (member.fullMember) licenseTeam.push(member)
        }
      }
      else if(this.addlicenseschoice == "needed") {
        for (let i = 0; i < team.permissions.length; i++){
          let member = team.permissions[i]
          if (member.fullMember && member.userdata.last_license < team.validUntil) licenseTeam.push(member)
        }
      }
      else licenseTeam = this.customlicense
      console.log(licenseTeam)

      //Posting License
      for(let i = 0; i < team.licenceCount; i++){
        let member = licenseTeam[i]
        let body = {
          created : now,
          creator : name,
          comment : comment.value,
          valid: true,
          validUntil : team.validUntil,
          phoneNumber: member.user.phoneNumber
        }
        let temp = this.postLicense(body)
        if(temp){
          body.validuntil = body.validUntil
          console.log(body)
          team.permissions[i].userdata.licenses.push(body)
        }
      }
      alert("Licenses have been added to full members")
      this.AddLicenses()
    },
    async postLicense(body){
      let backendAPI = "http://tiw.traxby.com/api/rest/lic"
      axios.post(backendAPI, body).then((res) => {
        console.log(res)
        return true
      })
    },
    AddCustomMember(member) {
      let contains = false
      for(let i = 0; i < this.customlicense.length; i++){
        if (this.customlicense[i].phoneNumber == member.phoneNumber) {
          contains = true
          break
        }
      }
      if (!contains) this.customlicense.push(member)
      console.log(this.customlicense)
    },

    //PUT REQUESTS__________________________________________________________________________
    submitNewTeamName(team) {
      let value = document.getElementById("newTeamNameInput")
      if(value.value == '') alert("Enter a new name!")
      else{
        let body = { 
          name: value.value,
          licenceCount: team.licenceCount
        }
        let backendAPI = "http://tiw.traxby.com/api/team/" + team.uuid + "/" + team.phoneNumber
        axios.put(backendAPI, body).then((res) => {
          console.log(res)
          alert("Team name changed to " + value.value)
          team.teamName = value.value
          team.name = value.value
          this.filterentries = this.entries
          this.defaultentries = this.entries
          this.paginateData(this.entries)
          this.allPages = $.array.pages(this.entries, this.currentEntries)
        })
      }
    },
    submitNewContactName(team) {
      let value = document.getElementById("newContactNameInput")
      if(value.value == '') alert("Enter a contact name!")
      else{
        let body = { 
          contactName: value.value,
          licenceCount: team.licenceCount
        }
        let backendAPI = "http://tiw.traxby.com/api/team/" + team.uuid + "/" + team.phoneNumber
        axios.put(backendAPI, body).then((res) => {
          console.log(res)
          alert("Contact name added")
          team.contactName = value.value
          this.filterentries = this.entries
          this.defaultentries = this.entries
          this.paginateData(this.entries)
          this.allPages = $.array.pages(this.entries, this.currentEntries)
        })
      }
    },
    submitNewContactEmail(team) {
      let value = document.getElementById("newContactEmailInput")
      if(value.value == '') alert("Enter a contact email!")
      else{
        let body = { 
          contactEmail: value.value,
          licenceCount: team.licenceCount
         }
        let backendAPI = "http://tiw.traxby.com/api/team/" + team.uuid + "/" + team.phoneNumber
        axios.put(backendAPI, body).then((res) => {
          console.log(res)
          alert("Contact email added")
          team.contactEmail = value.value
          this.filterentries = this.entries
          this.defaultentries = this.entries
          this.paginateData(this.entries)
          this.allPages = $.array.pages(this.entries, this.currentEntries)
        })
      }
    },
    submitNewLicenseAmount(team) {
      let value = document.getElementById("newLicenseAmountInput")
      value = parseInt(value.value)
      if(value == '') alert("Enter a license amount!")
      else{
        let body = { licenceCount: value }
        let backendAPI = "http://tiw.traxby.com/api/team/" + team.uuid + "/" + team.phoneNumber
        axios.put(backendAPI, body).then((res) => {
          console.log(res)
          alert("New licence count added!")
          team.licenceCount = value
          team.licenceCountmax = value
          team.licenceCountmin = value
          this.filterentries = this.entries
          this.defaultentries = this.entries
          this.paginateData(this.entries)
          this.allPages = $.array.pages(this.entries, this.currentEntries)
        })
      }
    },
    submitNewLicenseDate(team) {
      let date = new Date(this.teamLicenseDate)
      let value = date.getTime()
      console.log(value)
      let body = {
        validUntil: value,
        licenceCount: team.licenceCount
      }
      let backendAPI = "http://tiw.traxby.com/api/team/" + team.uuid + "/" + team.phoneNumber
      axios.put(backendAPI, body).then((res) => {
        console.log(res)
        alert("New license date added")
        team.validUntil = value
        team.validDate = this.teamLicenseDate
        this.filterentries = this.entries
        this.defaultentries = this.entries
        this.paginateData(this.entries)
        this.allPages = $.array.pages(this.entries, this.currentEntries)
      })
    },
    submitNewHuntCode(team) {
      let value = document.getElementById("newHuntCodeInput")
      if(value.value == '') alert("Enter a hunt code!")
      else{
        let body = { 
          permanentHuntCode: value.value,
          licenceCount: team.licenceCount
         }
        let backendAPI = "http://tiw.traxby.com/api/team/" + team.uuid + "/" + team.phoneNumber
        axios.put(backendAPI, body).then((res) => {
          console.log(res)
          alert("Hunt code added")
          team.permanentHuntCode = value.value
          this.filterentries = this.entries
          this.defaultentries = this.entries
          this.paginateData(this.entries)
          this.allPages = $.array.pages(this.entries, this.currentEntries)
        })
      }
    },
    submitPermission(team, member) {
      let perm = ""
      if (member.permission == "admin") {
        perm = "view"
        member.permission = "view"
      } else if (member.permission == "view") {
        perm = "admin"
        member.permission = "admin"
      } else {
        perm = "view"
        member.permission = "view"
      }
      let backendAPI = "http://tiw.traxby.com/api/team/" + team.uuid + "/permissions/" + member.uuid + "/" + team.phoneNumber
      let body = { 
        user: {
          phoneNumber:team.phoneNumber
        },
        permission: perm
      }
      axios.put(backendAPI, body).then((res) => {
        console.log(res)
      })
    },
    submitStatus(team, member) {
      let status
      if (member.fullMember == null) {
        member.fullMember = true
        status = true
      } else if (member.fullMember == false) {
        member.fullMember = true
        status = true
      } else {
        status = false
        member.fullMember = false
      }
      let backendAPI = "http://tiw.traxby.com/api/team/" + team.uuid + "/permissions/" + member.uuid + "/" + team.phoneNumber
      let body = { 
        user: {
          phoneNumber:team.phoneNumber
        },
        fullMember: status
      }
      axios.put(backendAPI, body).then((res) => {
        console.log(res)
      })
    },

    //DELETE REQUESTS__________________________________________________________________________
    submitDeleteTeam(team) {
      let conf = confirm("Do you want to delete this team?")
      let backendAPI = "http://tiw.traxby.com/api/team/"+team.uuid+"/"+team.phoneNumber
      if(conf){
        axios.delete(backendAPI).then((res) => {
          console.log(res)
          for(let i = 0; i < this.entries.length; i++){
            if(this.entries[i].uuid == team.uuid){
              this.entries.splice(i, 1)
              this.filterentries = this.entries
              this.defaultentries = this.entries
              this.paginateData(this.entries)
              this.allPages = $.array.pages(this.entries, this.currentEntries)
              this.TogglePopup("teamsInfoTrigger")
              this.TogglePopup("changeTeamTrigger")
              setTimeout(() => {}, 1)
              break
            }
          }
          alert("Team has been deleted")
        })
      }
    },
    submitDeleteMember(team, member) {
      let answer = confirm("are you sure you want to delete this user?")
      if (answer) {
        let backendAPI = "http://tiw.traxby.com/api/team/" + team.uuid + "/permissions/" + member.uuid + "/" + member.user.phoneNumber
        axios.delete(backendAPI).then((res) => {
          for (let i = 0; i < team.permissions.length; i++) {
            if (member.uuid == team.permissions[i].uuid) {
              if(team.permissions[i].fullMember) { 
                team.fullmembers--
                team.fullmembersmax--
                team.fullmembersmin--
              }
              else {
                team.othermembers--
                team.othermembersmax--
                team.othermembersmin--
              }
              team.permissions.splice(i, 1)
                team.members--
                break
            }
          }
          alert("Member has been deleted from team")
          console.log(res)
          this.paginateData(this.entries)
          this.allPages = $.array.pages(this.entries, this.currentEntries)
        })
      }
    },
    
    //FILTERS______________________________________________________________________________
    //MAIN FILTER FUNCTION
    filterByColumn() {
      this.resetSortIcon()
      this.currentPage = 1
      this.entries = this.defaultentries
      const filterCol = $.object.removeBy(this.col, "")
      let filterData = this.getCurrentEntries()
      if (Object.entries(filterCol).length >= 1) filterData = this.filter(filterCol)
      this.entries = filterData
      this.filterentries = this.entries
      this.resetSortLogic()
      this.paginateData(this.entries)
      this.allPages = $.array.pages(this.entries, this.currentEntries)
    },

    //FILTER FUNCTION HELP
    filter(filterObj) {
      let filteredArray = Array.from(this.getCurrentEntries())
      for (let [key, val] of Object.entries(filterObj, "", 0)) {
        const filterfromArr = filteredArray.filter((obj) => {
          if (key in obj) {
            //filtering strings
            if (!key.includes("min") && !key.includes("max")) {
              if (obj[key] == Number(val)) {
                return true
              } else if (
                obj[key] &&
                obj[key].toLowerCase().includes(val.toLowerCase())
              ) {
                return true
              }
            }
            
            //filtering integers and dates
            else if (key.includes("min") || key.includes("max")) {
              if (key.includes("Until")) {
                if (val.length >= 4) {
                  let date, yyyy, valms, value;
                  if (val.includes("-")) {
                    valms = val.split("-");
                    yyyy = valms[0];
                    if (valms[1] != "" && valms[1] != null) {
                      let mm;
                      if (valms[1].length < 2) {
                        mm = "0" + valms[1];
                      } else {
                        mm = valms[1];
                      }
                      if (valms[2] != "" && valms[2] != null) {
                        let dd;
                        if (valms[2].length < 2) {
                          dd = "0" + valms[2];
                        } else {
                          dd = valms[2];
                        }
                        date = new Date([yyyy, mm, dd].join("-"));
                      } else {
                        date = new Date([yyyy, mm].join("-"));
                      }
                    } else {
                      date = new Date(yyyy);
                    }
                  } else {
                    yyyy = val;
                    date = new Date(yyyy);
                  }
                  let object = Number(obj[key]);
                  if (key.includes("min")) {
                    value = date.getTime() - 7200000;
                    if (object >= value) {
                      return true;
                    }
                  } else if (key.includes("max")) {
                    value = date.getTime() + 79199999;
                    if (object <= value) {
                      return true;
                    }
                  }
                }
              }
              //integers
                let object = Number(obj[key])
                let value = Number(val)
                if (key.includes("min")) {
                  if (object >= value) {
                    return true
                  }
                } else if (key.includes("max")) {
                  if (object <= value) {
                    return true
                  }
                }
            }
          }
          return false
        })
        filteredArray = filterfromArr
      }
      return filteredArray
    },
    

    //SORTING______________________________________________________________________________
    sortByColumn(column) {
      let sortedEntries = this.getCurrentEntries()
      let sortedColumn = this.sortcol[column]
      if (sortedColumn === "") {
        this.sortcol[column] = "asc"
        if (column == "phoneNumber") this.sortnumber = "asc"
        else if (column == "ownerName") this.sortownername = "asc"
        else if (column == "teamName") this.sortteamname = "asc"
        else if (column == "fullmembers") this.sortfullmembers = "asc"
        else if (column == "othermembers") this.sortothermembers = "asc"
        else if (column == "licenceCount") this.sortlicensescount = "asc"
        else if (column == "validUntil") this.sortvaliduntil = "asc"
        sortedEntries = $.array.sortBy(this.getCurrentEntries(), column, "asc")
        this.entries = sortedEntries }
      else if (sortedColumn === "asc") {
        this.sortcol[column] = "desc"
        if (column == "phoneNumber") this.sortnumber = "desc"
        else if (column == "ownerName") this.sortownername = "desc"
        else if (column == "teamName") this.sortteamname = "desc"
        else if (column == "fullmembers") this.sortfullmembers = "desc"
        else if (column == "othermembers") this.sortothermembers = "desc"
        else if (column == "licenceCount") this.sortlicensescount = "desc"
        else if (column == "validUntil") this.sortvaliduntil = "desc"
        sortedEntries = $.array.sortBy(this.getCurrentEntries(), column, "desc")
        this.entries = sortedEntries }
      else if (sortedColumn === "desc") {
        this.sortcol[column] = ""
        if (column == "phoneNumber") this.sortnumber = "default"
        else if (column == "ownerName") this.sortownername = "default"
        else if (column == "teamName") this.sortteamname = "default"
        else if (column == "fullmembers") this.sortfullmembers = "default"
        else if (column == "othermembers") this.sortothermembers = "default"
        else if (column == "licenceCount") this.sortlicensescount = "default"
        else if (column == "validUntil") this.sortvaliduntil = "default"
        this.entries = this.filterentries }
      this.paginateData(this.entries)
    },

    //RESETS______________________________________________________________________________________
    resetTeams() {
      this.resetSortIcon()
      this.resetSortLogic()
      this.entries = this.defaultentries
      this.filterentries = this.defaultentries
      this.currentPage = 1
      this.searchInput = ""
      this.col = {
        phoneNumber: "",
        ownerName: "",
        teamName: "",
        fullmembers: "",
        othermembers: "",
        licenceCount: "",
        validUntil: ""
      },
      this.paginateEntries()
    },
    resetSortIcon() {
      this.sortnumber = "default"
      this.sortownername = "default"
      this.sortteamname= "default"
      this.sortfullmembers = "default"
      this.sortothermembers = "default"
      this.sortlicensescount = "default"
      this.sortvaliduntil = "default"
    },
    resetSortLogic() {
      this.sortcol["phoneNumber"] = ""
      this.sortcol["ownerName"] = ""
      this.sortcol["teamName"] = ""
      this.sortcol["fullmembers"] = ""
      this.sortcol["othermembers"] = ""
      this.sortcol["licenceCount"] = ""
      this.sortcol["validUntil"] = ""
    },

    //PAGINATION ETC MISC
    
    paginateEntries() {
      if (this.searchInput.length >= 1) {
        this.searchEntries = $.array.search(this.entries, this.searchInput)
        this.paginateData(this.searchEntries)
      } else {
        this.searchEntries = []
        this.paginateData(this.entries)
      }
    },
    paginateEvent(page) {
      this.currentPage = page
      this.paginateEntries()
    },
    searchEvent() {
      this.resetSortIcon()
      this.resetSortLogic()
      this.currentPage = 1
      this.paginateEntries()
    },
    paginateData(data) {
      this.filteredEntries = $.array.paginate(data, this.currentPage, this.currentEntries)
      this.allPages = $.array.pages(data, this.currentEntries)
    },
    getCurrentEntries() {
      return this.searchEntries.length <= 0 ? this.entries : this.searchEntries
    },
    loginPortal(user) {
      let pin = this.portalPIN
      let cell = user.userdata.cellnumber
      console.log(user)
      let apiURL = "http://tiw.traxby.com/api/portal-auth/"+cell+"/"+pin
      axios.get(apiURL).then(res => {
        console.log(res)
        alert("User signed in")
        this.portalPIN = ""
      })
    },
  },
};
</script>

<style scoped>
.numberSearch {
  display: flex;
}
.bold {
  font-weight: bold;
}
html,
body {
  max-width: 100%;
}
th,
td,
tr,
select,
input,
body {
  font-size: 10px;
}
th {
  font-weight: normal;
}
button {
  font-size: 10px;
}
.invalidSoon {
  background-color: yellow;
}
.invalid {
  background-color: salmon;
}
.search {
  font-weight: bold;
  color: orangered;
}
.form-search {
  background-color: salmon;
  font-weight: bold;
}
/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
.lds-ring-sm {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-ring-sm div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000 transparent transparent transparent;
}
.lds-ring-sm div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring-sm div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring-sm div:nth-child(3) {
  animation-delay: -0.15s;
}
.popper {
  margin-top: 10%;
}
.butt{
    font-size: 10px;
    font-weight: normal;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 768px) {
}
</style>

<template>
<!--ADD LICENCE TO DO-->
  <Modal v-if="popupTriggers.addLicenseTrigger" :TogglePopup="() => TogglePopup('addLicenseTrigger')">
  <div>
    <h5>Add Licenses</h5>
    <p style="font-size: 10px; margin-top: 5px; margin-bottom: -1px">cellnumber;comment;date(dd.mm.yyyy)</p>
    <textarea style="width: 400px; font-size:10px" class="form-control" v-model="licenseInfo" placeholder=".."/>
    <button type="button" style="margin-top:5px" class="btn btn-outline-dark btn-rounded" data-mdb-ripple-color="dark" v-on:click="addLicense()">
      Add Licenses
    </button>
  </div>
  </Modal>

<!--USER-->
  <Modal v-if="popupTriggers.usersButtonTrigger" :TogglePopup="() => TogglePopup('usersButtonTrigger')">
    <div>
      <p style="font-weight: bold; font-size: 14px">General Info</p>
      <table class="table table-hover">
        <thead>
          <tr>
            <th class="bold">Name</th>
            <th class="bold">Cellnumber</th>
            <th class="bold">Email</th>
            <th class="bold">Created</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>{{ user.name }}</th>
            <th>{{ user.cellnumber }}</th>
            <th>{{ user.email }}</th>
            <th>{{ user.created_date }}</th>
          </tr>
        </tbody>
      </table>

      <p style="font-weight: bold; font-size: 14px">Teams Info</p>
      <table class="table table-hover">
        <thead>
          <tr>
            <th class="bold">Team name</th>
            <th class="bold">Role</th>
            <th class="bold">Team owner</th>
            <th class="bold">Team owner number</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in user.teamsinfo" :key="user">
            <th>{{ user.teamName }}</th>
            <th>{{ user.teamRole }}</th>
            <th>{{ user.teamOwner }}</th>
            <th>{{ user.teamOwnerNumber }}</th>
          </tr>
        </tbody>
      </table>

      <p style="font-weight: bold; font-size: 14px">Group Info</p>
      <table class="table table-hover">
        <thead>
          <tr>
            <th class="bold">Group</th>
            <th class="bold">Info</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th class="bold">Groups created</th>
            <th scope="row">
              {{ user.created_hunts }}
            </th>
          </tr>
          <tr>
            <th class="bold">Groups joined</th>
            <th scope="row">
              {{ user.all_hunts }}
            </th>
          </tr>
          <tr>
            <th class="bold">Last group</th>
            <th scope="row">
              <div v-if="user.last_joined_date == ''">None</div>
              <div v-else>
                {{ user.last_joined_date }}
              </div>
            </th>
          </tr>
        </tbody>
      </table>

      <div class="d-flex justify-content-between">
        <p style="font-weight: bold; font-size: 14px">Licenses</p>
        <button class="btn btn-outline-dark btn-rounded" v-on:click="toggleUserLicense()">Add License</button>
      </div>
      
      <div v-if="togglenewlicense">
        <p style="font-size: 10px; margin-bottom: -2px">Date</p>
        <input v-model="userLicenseDate" type="date" class="form-control" style="width:60%" />
        <p style="font-size: 10px; margin-bottom: -2px">Comment</p>
        <input id="newLicenseComment" type="text" class="form-control" style="width:60%" />
        <button v-on:click="submitSingleLicense(user)" class="btn btn-outline-dark btn-rounded mt-2">Add License</button>
      </div>
      
      <table class="table table-hover">
        <thead>
          <tr>
            <th class="bold">#</th>
            <th class="bold">Comment</th>
            <th class="bold">Creator</th>
            <th class="bold">Product</th>
            <th class="bold">Created</th>
            <th class="bold">Valid until</th>
          </tr>
        </thead>
        <tbody v-if="!togglelicenses && user.licenses != null">
          <tr>
            <th>1</th>
            <th>{{ user.licenses[0].comment }}</th>
            <th>{{ user.licenses[0].creator }}</th>
            <th>{{ user.licenses[0].product }}</th>
            <th>{{ new Date(user.licenses[0].created).getFullYear() }}-{{("0" + (new Date(user.licenses[0].created).getMonth() + 1)).slice(-2)}}-{{("0" + new Date(user.licenses[0].created).getDate()).slice(-2)}}</th>
            <th>{{ new Date(user.licenses[0].validuntil).getFullYear() }}-{{("0" + (new Date(user.licenses[0].validuntil).getMonth() + 1)).slice(-2)}}-{{("0" + new Date(user.licenses[0].validuntil).getDate()).slice(-2)}}</th>
          </tr>
        </tbody>
        <tbody v-else-if="togglelicenses">
          <tr v-for="(user, index) in user.licenses" :key="user">
            <th scope="row">{{ index + 1 }}</th>
            <th scope="row">{{ user.comment }}</th>
            <th scope="row">{{ user.creator }}</th>
            <th scope="row">{{ user.product }}</th>
            <th scope="row">{{ new Date(user.created).getFullYear() }}-{{("0" + (new Date(user.created).getMonth() + 1)).slice(-2)}}-{{ ("0" + new Date(user.created).getDate()).slice(-2) }}</th>
            <th scope="row">{{ new Date(user.validuntil).getFullYear() }}-{{("0" + (new Date(user.validuntil).getMonth() + 1)).slice(-2)}}-{{ ("0" + new Date(user.validuntil).getDate()).slice(-2) }}</th>
          </tr>
        </tbody>
      </table>

      <div class="row justify-content-center" v-if="!togglelicenses">
        <svg v-on:click="toggleLicenses()" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-expand" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8zM7.646.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 1.707V5.5a.5.5 0 0 1-1 0V1.707L6.354 2.854a.5.5 0 1 1-.708-.708l2-2zM8 10a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 14.293V10.5A.5.5 0 0 1 8 10z" /></svg>
      </div>
      <div class="row justify-content-center" v-else-if="togglelicenses">
        <svg v-on:click="toggleLicenses()" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-collapse" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8zm7-8a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 4.293V.5A.5.5 0 0 1 8 0zm-.5 11.707-1.146 1.147a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 11.707V15.5a.5.5 0 0 1-1 0v-3.793z" /></svg>
      </div>


      <p style="font-weight: bold; font-size: 14px">Devices</p>
      <table class="table table-hover">
        <thead>
          <tr>
            <th class="bold">#</th>
            <th class="bold">App Language</th>
            <th class="bold">App Version</th>
            <th class="bold">Created</th>
            <th class="bold">Email</th>
            <th class="bold">Manufacturer</th>
            <th class="bold">Model</th>
            <th class="bold">Name</th>
            <th class="bold">OS</th>
            <th class="bold">OS Version</th>
          </tr>
        </thead>
        <tbody v-if="!toggledevices && user.devices != null">
          <tr>
            <th>1</th>
            <th>{{ user.devices[0].applanguage }}</th>
            <th>{{ user.devices[0].appversion }}</th>
            <th>{{ new Date(user.devices[0].created).getFullYear() }}-{{("0" + (new Date(user.devices[0].created).getMonth() + 1)).slice(-2)}}-{{("0" + new Date(user.devices[0].created).getDate()).slice(-2)}}</th>
            <th>{{ user.devices[0].email }}</th>
            <th>{{ user.devices[0].manufacturer }}</th>
            <th>{{ user.devices[0].model }}</th>
            <th>{{ user.devices[0].name }}</th>
            <th>{{ user.devices[0].opsys }}</th>
            <th>{{ user.devices[0].opsysversion }}</th>
          </tr>
        </tbody>
        <tbody v-else-if="toggledevices">
          <tr v-for="(user, index) in user.devices" :key="user">
            <th scope="row">{{ index + 1 }}</th>
            <th scope="row">{{ user.applanguage }}</th>
            <th scope="row">{{ user.appversion }}</th>
            <th scope="row">{{ new Date(user.created).getFullYear() }}-{{("0" + (new Date(user.created).getMonth() + 1)).slice(-2)}}-{{("0" + new Date(user.created).getDate()).slice(-2)}}</th>
            <th scope="row">{{ user.email }}</th>
            <th scope="row">{{ user.manufacturer }}</th>
            <th scope="row">{{ user.model }}</th>
            <th scope="row">{{ user.name }}</th>
            <th scope="row">{{ user.opsys }}</th>
            <th scope="row">{{ user.opsysversion }}</th>
          </tr>
        </tbody>
      </table>

      <div class="row justify-content-center" v-if="!toggledevices">
        <svg v-on:click="toggleDevices()" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-expand" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8zM7.646.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 1.707V5.5a.5.5 0 0 1-1 0V1.707L6.354 2.854a.5.5 0 1 1-.708-.708l2-2zM8 10a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 14.293V10.5A.5.5 0 0 1 8 10z" /></svg>
      </div>
      <div class="row justify-content-center" v-else-if="toggledevices">
        <svg v-on:click="toggleDevices()" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-collapse" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8zm7-8a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 4.293V.5A.5.5 0 0 1 8 0zm-.5 11.707-1.146 1.147a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 11.707V15.5a.5.5 0 0 1-1 0v-3.793z" /></svg>
      </div>

      <p style="font-weight: bold; font-size: 14px">Notes</p>
      <table class="table table-hover">
        <thead>
          <tr>
            <th class="bold">#</th>
            <th class="bold">Created</th>
            <th class="bold">Comment</th>
          </tr>
        </thead>
        <tbody v-if="!togglenotes && user.notes.length != 0">
          <tr>
            <th>1</th>
            <th>{{ new Date(user.notes[0].created).getFullYear() }}-{{("0" + (new Date(user.notes[0].created).getMonth() + 1)).slice(-2)}}-{{("0" + new Date(user.notes[0].created).getDate()).slice(-2)}}</th>
            <th v-if="user.notes[0].data.text != null">{{ user.notes[0].data.text }}</th>
            <th scope="row" v-else-if="user.notes[0].data.text == null && user.notes[0].name == 'huntloc_login'">Huntloc Login</th>
            <th scope="row" v-else-if="user.notes[0].data.text == null && user.notes[0].name == 'liveteam_login'">Liveteam Login</th>
            <th scope="row" v-else></th>
          </tr>
        </tbody>
        <tbody v-else-if="togglenotes">
          <tr v-for="(notes, index) in user.notes" :key="notes">
            <th scope="row">{{ index + 1 }}</th>
            <th>{{ new Date(notes.created).getFullYear() }}-{{("0" + (new Date(notes.created).getMonth() + 1)).slice(-2)}}-{{("0" + new Date(notes.created).getDate()).slice(-2)}}</th>
            <th scope="row" v-if="notes.data.text != null">{{ notes.data.text }}</th>
            <th scope="row" v-else-if="notes.data.text == null && notes.name == 'huntloc_login'">Huntloc Login</th>
            <th scope="row" v-else-if="notes.data.text == null && notes.name == 'liveteam_login'">Liveteam Login</th>
            <th scope="row" v-else></th>
          </tr>
        </tbody>
      </table>

      <div class="row justify-content-center" v-if="!togglenotes">
        <svg v-on:click="toggleNotes()" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-expand" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8zM7.646.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 1.707V5.5a.5.5 0 0 1-1 0V1.707L6.354 2.854a.5.5 0 1 1-.708-.708l2-2zM8 10a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 14.293V10.5A.5.5 0 0 1 8 10z" /></svg>
      </div>
      <div class="row justify-content-center" v-else-if="togglenotes">
        <svg v-on:click="toggleNotes()" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-collapse" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8zm7-8a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 4.293V.5A.5.5 0 0 1 8 0zm-.5 11.707-1.146 1.147a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 11.707V15.5a.5.5 0 0 1-1 0v-3.793z" /></svg>
      </div>

      <p style="font-weight: bold; font-size: 14px">Add note</p>
      <textarea id="userNoteInput" style="height: 100px" class="form-control" />
      <button type="button" class="btn btn-outline-dark btn-rounded" data-mdb-ripple-color="dark" v-on:click="addNotes(user)">Add Note</button>

            <div class="justify-content-between d-flex"><span><p style="font-weight: bold; font-size: 14px">Portal Authentication</p></span><span><button class="btn btn-outline-dark btn-rounded" v-on:click="toPortal()">Portal</button></span></div>

      <input type="text" class="form-control" placeholder="" v-model="portalPIN"/>
      <button type="button" class="btn btn-outline-dark btn-rounded" data-mdb-ripple-color="dark" v-on:click="loginPortal(user)">Login</button>
    </div>
  </Modal>

<!--MAIN-->
  <div class="col bg-light" style="width: 100%" v-if="!store.state.usersLoaded && !store.state.localUsers">
    <div class="lds-ring" style="margin-left: 50%; margin-top: 20%">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <div v-else class="col bg-light min-vh-100">
    <div class="d-flex justify-content-between mt-3">
<div class="d-flex">
        <div class="d-flex" style="font-size: 12px">
          <p class="pt-1">Entries:</p>
          <select v-model="currentEntries" class="form-control form-control-sm mb-3" style="font-size: 10px; font-weight: bold" v-on:change="paginateEntries">
            <option v-for="se in showEntries" :key="se" :value="se">
              {{ se }}
            </option>
          </select>
        </div>
        <div>
          <div style="font-size: 10px">
            <p style="margin-left:5px">
              <span style="height:7px;width:7px;background-color:salmon;border-radius:50%;display:inline-block"></span>  Expired<br>
              <span style="height:7px;width:7px;background-color:yellow;border-radius:50%;display:inline-block"></span>  Expires in a month<br>
            </p>
          </div>
        </div>
        <div>
        <p class="me-3 ml-5 d-flex">Last updated: {{ last_updated }}  <div v-if="!store.state.usersLoaded" class="d-flex">
          <div class="lds-ring-sm">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          </div>
        </p>
	</div>
      </div>
      <div class="d-flex justify-content-between">
        <span>
        <button v-on:click="triggerAddLicense()" class="btn btn-sm mx-2 btn-outline-dark btn-rounded">
        Add License
        </button>
        </span>
        <span
          ><button
            v-on:click="downloadCSV()"
            class="btn btn-sm mx-2 btn-outline-dark btn-rounded"
          >
            Download CSV
          </button></span
        >
        <span
          ><button
            class="btn btn-sm mx-2 btn-outline-dark btn-rounded"
            v-on:click="resetUsers()"
          >
            Reset filters
          </button></span
        >
        <span><input
            class="form-control me-3 search"
            type="search"
            placeholder="Search.."
            v-model="searchInput"
            v-on:keydown.enter="searchEvent"
            aria-label="Search"
        /></span>
      </div>
    </div>

    <table id="table" class="table table-bordered table-hover overflow-auto">
      <thead>
        <tr class="h6">
          <th>
            <div class="d-flex justify-content-between">
              <span>Name</span>
              <span
                v-if="sortname == 'default'"
                v-on:click.prevent="sortByColumn('name')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortname == 'desc'"
                v-on:click.prevent="sortByColumn('name')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortname == 'asc'"
                v-on:click.prevent="sortByColumn('name')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                  /></svg
              ></span>
            </div>
          </th>
          <th>
            <div class="d-flex justify-content-between">
              <span>Cellnumber</span>
              <span
                v-if="sortnumber == 'default'"
                v-on:click.prevent="sortByColumn('cellnumber')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortnumber == 'desc'"
                v-on:click.prevent="sortByColumn('cellnumber')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortnumber == 'asc'"
                v-on:click.prevent="sortByColumn('cellnumber')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                  /></svg
              ></span>
            </div>
          </th>
          <th>
            <div class="d-flex justify-content-between">
              <span>Country</span>
              <span
                v-if="sortcountry == 'default'"
                v-on:click.prevent="sortByColumn('country')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortcountry == 'desc'"
                v-on:click.prevent="sortByColumn('country')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortcountry == 'asc'"
                v-on:click.prevent="sortByColumn('country')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                  /></svg
              ></span>
            </div>
          </th>
          <th>
            <div class="d-flex justify-content-between">
              <span>Email</span>
              <span
                v-if="sortemail == 'default'"
                v-on:click.prevent="sortByColumn('email')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortemail == 'desc'"
                v-on:click.prevent="sortByColumn('email')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortemail == 'asc'"
                v-on:click.prevent="sortByColumn('email')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                  /></svg
              ></span>
            </div>
          </th>
          <th>
            <div class="d-flex justify-content-between">
              <span>Created</span>
              <span
                v-if="sortcreated == 'default'"
                v-on:click.prevent="sortByColumn('created_date')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortcreated == 'desc'"
                v-on:click.prevent="sortByColumn('created_date')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortcreated == 'asc'"
                v-on:click.prevent="sortByColumn('created_date')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                  /></svg
              ></span>
            </div>
          </th>
          <th>
            <div class="d-flex justify-content-between">
              <span>Last Group</span>
              <span
                v-if="sortlastgroup == 'default'"
                v-on:click.prevent="sortByColumn('last_joined_date')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortlastgroup == 'desc'"
                v-on:click.prevent="sortByColumn('last_joined_date')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortlastgroup == 'asc'"
                v-on:click.prevent="sortByColumn('last_joined_date')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                  /></svg
              ></span>
            </div>
          </th>
          <th>
            <div class="d-flex justify-content-between">
              <span>Places</span>
              <span
                v-if="sortplaces == 'default'"
                v-on:click.prevent="sortByColumn('places')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortplaces == 'desc'"
                v-on:click.prevent="sortByColumn('places')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortplaces == 'asc'"
                v-on:click.prevent="sortByColumn('places')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                  /></svg
              ></span>
            </div>
          </th>
          <th>
            <div class="d-flex justify-content-between">
              <span>Created Groups</span>
              <span
                v-if="sortcreatedgroups == 'default'"
                v-on:click.prevent="sortByColumn('created_hunts')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortcreatedgroups == 'desc'"
                v-on:click.prevent="sortByColumn('created_hunts')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortcreatedgroups == 'asc'"
                v-on:click.prevent="sortByColumn('created_hunts')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                  /></svg
              ></span>
            </div>
          </th>
          <th>
            <div class="d-flex justify-content-between">
              <span>All Groups</span>
              <span
                v-if="sortallgroups == 'default'"
                v-on:click.prevent="sortByColumn('all_hunts')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortallgroups == 'desc'"
                v-on:click.prevent="sortByColumn('all_hunts')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortallgroups == 'asc'"
                v-on:click.prevent="sortByColumn('all_hunts')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                  /></svg
              ></span>
            </div>
          </th>
          <th>
            <div class="d-flex justify-content-between">
              <span>Devices</span>
              <span
                v-if="sortdevices == 'default'"
                v-on:click.prevent="sortByColumn('deviceslength')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortdevices == 'desc'"
                v-on:click.prevent="sortByColumn('deviceslength')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortdevices == 'asc'"
                v-on:click.prevent="sortByColumn('deviceslength')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                  /></svg
              ></span>
            </div>
          </th>
          <th>
            <div class="d-flex justify-content-between">
              <span>App</span>
              <span
                v-if="sortapp == 'default'"
                v-on:click.prevent="sortByColumn('app')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortapp == 'desc'"
                v-on:click.prevent="sortByColumn('app')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortapp == 'asc'"
                v-on:click.prevent="sortByColumn('app')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                  /></svg
              ></span>
            </div>
          </th>
          <th>
            <div class="d-flex justify-content-between">
              <span>Phone</span>
              <span
                v-if="sortphone == 'default'"
                v-on:click.prevent="sortByColumn('phone')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortphone == 'desc'"
                v-on:click.prevent="sortByColumn('phone')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortphone == 'asc'"
                v-on:click.prevent="sortByColumn('phone')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                  /></svg
              ></span>
            </div>
          </th>
          <th>
            <div class="d-flex justify-content-between">
              <span>Licenses</span>
              <span
                v-if="sortlicenses == 'default'"
                v-on:click.prevent="sortByColumn('licenseslength')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortlicenses == 'desc'"
                v-on:click.prevent="sortByColumn('licenseslength')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortlicenses == 'asc'"
                v-on:click.prevent="sortByColumn('licenseslength')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                  /></svg
              ></span>
            </div>
          </th>
          <th>
            <div class="d-flex justify-content-between">
              <span>Recent License</span>
              <span
                v-if="sortrecentlicense == 'default'"
                v-on:click.prevent="sortByColumn('last_license')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortrecentlicense == 'desc'"
                v-on:click.prevent="sortByColumn('last_license')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortrecentlicense == 'asc'"
                v-on:click.prevent="sortByColumn('last_license')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                  /></svg
              ></span>
            </div>
          </th>
          <th>
            <div class="d-flex justify-content-between">
              <span>Notes</span>
              <span
                v-if="sortnotes == 'default'"
                v-on:click.prevent="sortByColumn('noteslength')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortnotes == 'desc'"
                v-on:click.prevent="sortByColumn('noteslength')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-up"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                  /></svg
              ></span>
              <span
                v-else-if="sortnotes == 'asc'"
                v-on:click.prevent="sortByColumn('noteslength')"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  fill="currentColor"
                  class="bi bi-arrow-down"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                  /></svg
              ></span>
            </div>
          </th>
          <th>Details</th>
        </tr>

        <tr>
          <td>
            <input
              type="search"
              class="form-control search"
              placeholder=""
              aria-label="Name"
              v-model="col.name"
              v-on:keyup="filterByColumn"
            />
          </td>
          <td>
            <input
              type="search"
              class="form-control search"
              placeholder=""
              v-model="col.cellnumber"
              v-on:keyup="filterByColumn"
            />
          </td>
          <td>
            <div v-if="col.country.length == 0">
              <select
                class="form-select"
                v-model="col.country"
                v-on:change="filterByColumn"
              >
                <option value="">Default</option>
                <option value=" "></option>
                <option value="Estonia">EST</option>
                <option value="Latvia">LVA</option>
                <option value="Lithuania">LTU</option>
                <option value="Finland">FIN</option>
                <option value="Ukraine">UKR</option>
                <option value="Russia">RUS</option>
                <option value="USA">USA</option>
                <option value="Germany">DEU</option>
                <option value="Ireland">IRL</option>
              </select>
            </div>
            <div v-else>
              <select
                class="form-select form-search"
                v-model="col.country"
                v-on:change="filterByColumn"
              >
                <option value="">Default</option>
                <option value=" "></option>
                <option value="Estonia">EST</option>
                <option value="Latvia">LVA</option>
                <option value="Lithuania">LTU</option>
                <option value="Finland">FIN</option>
                <option value="Ukraine">UKR</option>
                <option value="Russia">RUS</option>
                <option value="USA">USA</option>
                <option value="Germany">DEU</option>
                <option value="Ireland">IRL</option>
              </select>
            </div>
          </td>
          <td>
            <input
              type="search"
              class="form-control search"
              placeholder=""
              v-model="col.email"
              v-on:keyup="filterByColumn"
            />
          </td>
          <td>
            <div>
              <span
                ><input
                  type="search"
                  class="form-control search"
                  placeholder=""
                  v-model="col.createddatemin"
                  v-on:keyup="filterByColumn"
              /></span>
              <span class="d-flex justify-content-center bold">to</span>
              <span
                ><input
                  type="search"
                  class="form-control search"
                  placeholder=""
                  v-model="col.createddatemax"
                  v-on:keyup="filterByColumn"
              /></span>
            </div>
          </td>
          <td>
            <div>
              <span
                ><input
                  type="search"
                  class="form-control search"
                  placeholder=""
                  v-model="col.last_joineddatemin"
                  v-on:keyup="filterByColumn"
              /></span>
              <span class="d-flex justify-content-center bold">to</span>
              <span
                ><input
                  type="search"
                  class="form-control search"
                  placeholder=""
                  v-model="col.last_joineddatemax"
                  v-on:keyup="filterByColumn"
              /></span>
            </div>
          </td>
          <td>
            <div>
              <span
                ><input
                  type="search"
                  class="form-control search"
                  placeholder=""
                  v-model="col.placesmin"
                  v-on:keyup="filterByColumn"
              /></span>
              <span class="d-flex justify-content-center bold">to</span>
              <span
                ><input
                  type="search"
                  class="form-control search"
                  placeholder=""
                  v-model="col.placesmax"
                  v-on:keyup="filterByColumn"
              /></span>
            </div>
          </td>
          <td>
            <div>
              <span
                ><input
                  type="search"
                  class="form-control search"
                  placeholder=""
                  v-model="col.created_huntsmin"
                  v-on:keyup="filterByColumn"
              /></span>
              <span class="d-flex justify-content-center bold">to</span>
              <span
                ><input
                  type="search"
                  class="form-control search"
                  placeholder=""
                  v-model="col.created_huntsmax"
                  v-on:keyup="filterByColumn"
              /></span>
            </div>
          </td>
          <td>
            <div>
              <span
                ><input
                  type="search"
                  class="form-control search"
                  placeholder=""
                  v-model="col.all_huntsmin"
                  v-on:keyup="filterByColumn"
              /></span>
              <span class="d-flex justify-content-center bold">to</span>
              <span
                ><input
                  type="search"
                  class="form-control search"
                  placeholder=""
                  v-model="col.all_huntsmax"
                  v-on:keyup="filterByColumn"
              /></span>
            </div>
          </td>
          <td>
            <div>
              <span
                ><input
                  type="search"
                  class="form-control search"
                  placeholder=""
                  v-model="col.deviceslengthmin"
                  v-on:keyup="filterByColumn"
              /></span>
              <span class="d-flex justify-content-center bold">to</span>
              <span
                ><input
                  type="search"
                  class="form-control search"
                  placeholder=""
                  v-model="col.deviceslengthmax"
                  v-on:keyup="filterByColumn"
              /></span>
            </div>
          </td>

          <td>
            <div v-if="col.app.length == 0">
              <select
                class="form-select"
                v-model="col.app"
                v-on:change="filterByColumn"
              >
                <option value="">Default</option>
                <option value=" "></option>
                <option value="Huntloc">Huntloc</option>
                <option value="Liveteam">Liveteam</option>
                <option value="Doglo">Doglo</option>
              </select>
            </div>
            <div v-else>
              <select
                class="form-select form-search"
                v-model="col.app"
                v-on:change="filterByColumn"
              >
                <option value="">Default</option>
                <option value=" "></option>
                <option value="Huntloc">Huntloc</option>
                <option value="Liveteam">Liveteam</option>
                <option value="Doglo">Doglo</option>
              </select>
            </div>
          </td>
          <td>
            <div v-if="col.phone.length == 0">
              <select
                class="form-select"
                v-model="col.phone"
                v-on:change="filterByColumn"
              >
                <option value="">Default</option>
                <option value=" "></option>
                <option value="Android">Android</option>
                <option value="iOS">iOS</option>
              </select>
            </div>
            <div v-else>
              <select
                class="form-select form-search"
                v-model="col.phone"
                v-on:change="filterByColumn"
              >
                <option value="">Default</option>
                <option value=" "></option>
                <option value="Android">Android</option>
                <option value="iOS">iOS</option>
              </select>
            </div>
          </td>
          <td>
            <div>
              <span
                ><input
                  type="search"
                  class="form-control search"
                  placeholder=""
                  v-model="col.licenseslengthmin"
                  v-on:keyup="filterByColumn"
              /></span>
              <span class="d-flex justify-content-center bold">to</span>
              <span
                ><input
                  type="search"
                  class="form-control search"
                  placeholder=""
                  v-model="col.licenseslengthmax"
                  v-on:keyup="filterByColumn"
              /></span>
            </div>
          </td>
          <td>
            <div>
              <span
                ><input
                  type="search"
                  class="form-control search"
                  placeholder=""
                  v-model="col.licenseslastdatemin"
                  v-on:keyup="filterByColumn"
              /></span>
              <span class="d-flex justify-content-center bold">to</span>
              <span
                ><input
                  type="search"
                  class="form-control search"
                  placeholder=""
                  v-model="col.licenseslastdatemax"
                  v-on:keyup="filterByColumn"
              /></span>
            </div>
          </td>
          <td>
            <div>
              <span
                ><input
                  type="search"
                  class="form-control search"
                  placeholder=""
                  v-model="col.noteslengthmin"
                  v-on:keyup="filterByColumn"
              /></span>
              <span class="d-flex justify-content-center bold">to</span>
              <span
                ><input
                  type="search"
                  class="form-control search"
                  placeholder=""
                  v-model="col.noteslengthmax"
                  v-on:keyup="filterByColumn"
              /></span>
            </div>
          </td>
          <td></td>
        </tr>
      </thead>

      <tbody>
        <tr v-for="td in tableData" :key="td">
          <td>{{ td.name }}</td>

          <td>{{ td.cellnumber }}</td>

          <td>{{ td.country }}</td>

          <td>{{ td.email }}</td>

          <td>{{ td.created_date }}</td>

          <td>{{ td.last_joined_date }}</td>

          <td>{{ td.places }}</td>

          <td>{{ td.created_hunts }}</td>

          <td>{{ td.all_hunts }}</td>

          <td>{{ td.deviceslength }}</td>

          <td>{{ td.app }}</td>

          <td>{{ td.phone }}</td>

          <td>{{ td.licenseslength }}</td>

          <td>
            <div
              v-if="new Date(td.last_license_date).getTime() < new Date().getTime()"
              v-bind:class="{ invalid: true }"
            >
              {{ td.last_license_date }}
            </div>
            <div
              v-else-if="
                new Date(td.last_license_date).getTime() <
                new Date().getTime() + 2629800000
              "
              v-bind:class="{ invalidSoon: true }"
            >
              {{ td.last_license_date }}
            </div>
            <div v-else>{{ td.last_license_date }}</div>
          </td>

          <td>{{ td.noteslength }}</td>

          <td class="d-flex justify-content-center">
            <svg
              v-on:click="userInfo(td)"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="currentColor"
              class="bi bi-info-circle"
              viewBox="0 0 16 16"
            >
              <path
                d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
              />
              <path
                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
              />
            </svg>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="d-flex flex-row justify-content-between w-100" id="pagination">
      <div class="pt-2" style="font-size: 12px; display: block">
        Show {{ showInfo.from }} to {{ showInfo.to }} of
        {{ showInfo.of }} entries
      </div>
      <div style="font-size: 10px">
        <ul class="list-group list-group-horizontal mb-1">
          <li
            :class="[
              'list-group-item border px-2',
              { disabled: currentPage === 1 },
            ]"
          >
            <a
              href="#"
              class="nav-link"
              v-on:click.prevent="(currentPage = 1), paginateEntries()"
              >First</a
            >
          </li>
          <li
            :class="[
              'list-group-item border px-2',
              { disabled: currentPage === 1 },
            ]"
          >
            <a
              href="#"
              class="nav-link"
              v-on:click.prevent="
                currentPage < 1 ? (currentPage = 1) : (currentPage -= 1),
                  paginateEntries()
              "
              >Prev</a
            >
          </li>
          <li
            v-for="pagi in showPagination"
            :key="pagi"
            :class="[
              'list-group-item border px-2',
              { ellipsis: pagi === '...', active: pagi === currentPage },
            ]"
          >
            <a
              href="#"
              class="nav-link bg-white text-dark"
              v-on:click.prevent="paginateEvent(pagi)"
              >{{ pagi }}</a
            >
          </li>
          <li
            :class="[
              'list-group-item border px-2',
              { disabled: currentPage === allPages },
            ]"
          >
            <a
              href="#"
              class="nav-link"
              v-on:click.prevent="
                currentPage > allPages
                  ? (currentPage = allPages)
                  : (currentPage += 1),
                  paginateEntries()
              "
              >Next</a
            >
          </li>
          <li
            :class="[
              'list-group-item border px-2',
              { disabled: currentPage === allPages },
            ]"
          >
            <a
              href="#"
              class="nav-link"
              v-on:click.prevent="(currentPage = allPages), paginateEntries()"
              >Last</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import * as $ from "alga-js"
import axios from "axios"
import { ref } from "vue"
import Modal from "@/components/Modal.vue"
import firebase from 'firebase'
import { inject } from 'vue'
import { mapState } from 'vuex'

export default {
  name: "UsersTable",
  components: {
    Modal,
  },
  setup() {
    const store = inject('store')
    const popupTriggers = ref({
      usersButtonTrigger: false,
      addLicenseTrigger: false
    });

    const TogglePopup = (trigger) => {
      popupTriggers.value[trigger] = !popupTriggers.value[trigger];
    };
    return {
      Modal,
      popupTriggers,
      TogglePopup,
      store
    }
  },
  mounted() {
    if(!this.store.state.usersLoaded){
      this.$store.dispatch('loadUsers')
    }
  },
  data() {
    return {
      sortname: "default",
      sortnumber: "default",
      sortcountry: "default",
      sortemail: "default",
      sortcreated: "default",
      sortlastgroup: "default",
      sortplaces: "default",
      sortcreatedgroups: "default",
      sortallgroups: "default",
      sortdevices: "default",
      sortapp: "default",
      sortphone: "default",
      sortlicenses: "default",
      sortrecentlicense: "default",
      sortnotes: "default",
      tablehover: false,
      notetext: "",
      sort: "default",
      portalPIN: "",
      togglenotes: false,
      togglelicenses: false,
      toggledevices: false,
      invalidSoon: false,
      invalid: false,
      title: "Users",
      loaded: false,
      licenseInfo: "",
      givenDate: "",
      dateMillis: 0,
      addedLicenseNumbers: [],
      csvData: [],
      togglenewlicense: false,
      userLicenseDate: 0,
      last_updated: 0,
      
      //Add license in detail tab for single user
      addSingleUserLicense: false,
      singleUserDate: 0,
      singleUserComment: "",

      columns: [
        { name: "name", text: "Name" },
        { name: "cellnumber", text: "Cellnumber" },
        { name: "country", text: "Country" },
        { name: "email", text: "Email" },
        { name: "created_date", text: "Created" },
        { name: "last_joined_date", text: "Last Hunt" },
        { name: "places", text: "Places" },
        { name: "created_hunts", text: "Created Hunts" },
        { name: "all_hunts", text: "All Hunts" },
        { name: "deviceslength", text: "Devices" },
        { name: "app", text: "App" },
        { name: "phone", text: "Phone" },
        { name: "licenseslength", text: "Licenses" },
        { name: "last_license", text: "Recent License" },
        { name: "noteslength", text: "Notes" },
        { name: "details", text: "Details" },
      ],
      entries: [],
      showEntries: [1, 5, 10, 15, 25, 50, 100, 250, 500, 1000, 1500],
      currentEntries: 50,
      filteredEntries: [],
      currentPage: 1,
      allPages: 1,
      searchInput: "",
      searchEntries: [],
      col: {
        name: "",
        cellnumber: "",
        country: "",
        email: "",
        created_date: "",
        last_joined_date: "",
        places: "",
        created_hunts: "",
        all_hunts: "",
        deviceslengthmin: "",
        deviceslengthmax: "",
        app: "",
        phone: "",
        licenseslength: "",
        last_license: "",
        noteslength: "",
      },
      sortcol: {
        name: "",
        cellnumber: "",
        country: "",
        email: "",
        created_date: "",
        last_joined_date: "",
        places: "",
        created_hunts: "",
        all_hunts: "",
        deviceslengthmin: "",
        deviceslengthmax: "",
        app: "",
        phone: "",
        licenseslength: "",
        last_license: "",
        noteslength: "",
      },
    };
  },
  computed: {
    showInfo() {
      return $.array.pageInfo(
        this.getCurrentEntries(),
        this.currentPage,
        this.currentEntries
      );
    },
    showPagination() {
      return $.array.pagination(this.allPages, this.currentPage, 3);
    },
    tableColumns() {
      return this.columns;
    },
    tableData() {
      return this.filteredEntries;
    },
    computed: mapState(['users']),
  },
  created() {
    this.getLocalUsers()
    this.getUsers()
  },
  methods: {
    //GET REQUESTS_________________________________________________________________
    getLocalUsers(){
      if(localStorage.length > 1){
        let storage = []
        for(let i = 0; i < 2002; i++){
          let user = JSON.parse(localStorage.getItem(i))
          storage.push(user)
        }
        let teamsAPI = "http://tiw.traxby.com/api/team/list_all"
        axios.get(teamsAPI).then((data) => {
          this.team = data.data
          for (let i = 0; i < this.team.length; i++) {
            this.team[i].phoneNumber = ""
            this.team[i].ownerName = ""
            this.team[i].teamName = ""
            if (this.team[i].permissions.length != 0 || this.team[i].permissions.length != null) {
              for (let j = 0; j < this.team[i].permissions.length; j++) {
                if (this.team[i].permissions[j].permission == "owner") {
                  this.team[i].phoneNumber = this.team[i].permissions[j].user.phoneNumber
                  this.team[i].ownerName = this.team[i].permissions[j].user.name
                  break
                }
              }
              if (this.team[i].name != null) this.team[i].teamName = this.team[i].name
            }
          }
        })
        this.last_updated = localStorage.getItem('last_updated')
        this.entries = storage
        this.filterentries = this.entries
        this.defaultentries = this.entries
        this.csvData = this.entries
        this.paginateData(this.entries)
        this.allPages = $.array.pages(this.entries, this.currentEntries)
      }
    },
    async getUsers() {
      if(this.store.state.usersLoaded){
        let teamsAPI = "http://tiw.traxby.com/api/team/list_all"
        axios.get(teamsAPI).then((data) => {
          this.team = data.data
          for (let i = 0; i < this.team.length; i++) {
            this.team[i].phoneNumber = ""
            this.team[i].ownerName = ""
            this.team[i].teamName = ""
            if (this.team[i].permissions.length != 0 || this.team[i].permissions.length != null) {
              for (let j = 0; j < this.team[i].permissions.length; j++) {
                if (this.team[i].permissions[j].permission == "owner") {
                  this.team[i].phoneNumber = this.team[i].permissions[j].user.phoneNumber
                  this.team[i].ownerName = this.team[i].permissions[j].user.name
                  break
                }
              }
              if (this.team[i].name != null) this.team[i].teamName = this.team[i].name
            }
          }
          this.entries = this.store.state.users
          this.filterentries = this.entries
          this.defaultentries = this.entries
          this.csvData = this.entries
          console.log(this.entries)
          this.paginateData(this.entries)
          this.allPages = $.array.pages(this.entries, this.currentEntries)
          this.searchEvent()
          this.filterByColumn()
          this.last_updated = localStorage.getItem('last_updated')
        })
      }
      else{
        setTimeout(() => {  this.getUsers()  }, 10000)
      }
    },
    userInfo(user) {
      this.user = user
      let apiURL = "http://tiw.traxby.com/api/activity/" + user.cellnumber
      axios.get(apiURL).then((res) => {
        this.user.notes = res.data
        this.user.teamsinfo = []
        this.teamsinfo = []
          for(let i = 0; i < this.team.length; i++){
            for(let j = 0; j < this.team[i].permissions.length; j++){
              if(this.user.cellnumber == this.team[i].permissions[j].user.phoneNumber){
                let teamname = this.team[i].teamName
                let role = this.team[i].permissions[j].permission
                let name = this.team[i].ownerName
                let number = this.team[i].phoneNumber
                this.teamsinfo.push({
                  teamName: teamname,
                  teamRole: role,
                  teamOwner: name,
                  teamOwnerNumber: number
              })
              }
            }
          }
          this.user.teamsinfo = this.teamsinfo
          this.TogglePopup("usersButtonTrigger")
      })
    }, 
    loginPortal(user) {
      let pin = this.portalPIN
      let cell = user.cellnumber
      let apiURL = "http://tiw.traxby.com/api/portal-auth/"+cell+"/"+pin
      axios.get(apiURL).then(res => {
        console.log(res)
        alert("User signed in")
        this.portalPIN = ""
      })
    },
    //POST REQUESTS_________________________________________________________________
    submitSingleLicense(user) {
      let now = Date.now()
      let email = firebase.auth().currentUser.email
      let name = email.split('@')[0].split('.')[0]
      name = name.charAt(0).toUpperCase() + name.slice(1)
      let comment = document.getElementById("newLicenseComment")
      let time = new Date(this.userLicenseDate)
      let body = {
        created : now,
        creator : name,
        comment : comment.value,
        valid : true,
        validUntil : time.getTime(),
        phoneNumber : user.cellnumber
      }
      console.log(body)
      let backendAPI = "http://tiw.traxby.com/api/rest/lic"
      axios.post(backendAPI, body).then((res) => {
        body.validuntil = body.validUntil
        console.log(res)
        user.licenses.push(body)
        alert("License added to "+user.cellnumber+" until "+this.userLicenseDate)
        this.togglenewlicense = !this.togglenewlicense
      })
    },
    addNotes(user) {
      let note = document.getElementById("userNoteInput")
      if (note.value != "") {
        let body = {
          name: "admin_note",
          created: Date.now(),
          data: { text: note.value },
          dataJsonStr: null,
        }
        let apiURL = "http://tiw.traxby.com/api/activity/" + user.cellnumber
        axios.post(apiURL, body).then((res) => {
          console.log(res)
          alert("Note has been added")
          document.getElementById("userNoteInput").value = ""
          user.notes.push(body)

        })
      }
    },
    //TOGGLES_______________________________________________________________
    toggleUserLicense() {
      this.togglenewlicense = !this.togglenewlicense
      let def = Date.now() + 31556952000
      let y = new Date(def).getFullYear()
      let m = ("0" + (new Date(def).getMonth() + 1)).slice(-2)
      let d = ("0" + (new Date(def).getDate())).slice(-2)
      this.userLicenseDate = y+"-"+m+"-"+d
    },
    triggerAddLicense(){
      this.TogglePopup("addLicenseTrigger")
      setTimeout(() => {}, 1)
    },
    //CSV Add Licenses
    addLicense(){
      let lines = this.licenseInfo.split('\n')
      let now = Date.now()
      let email = firebase.auth().currentUser.email
      let name = email.split('@')[0].split('.')[0]
      name = name.charAt(0).toUpperCase() + name.slice(1)
      
      //TOCHECK
      for(let i = 0; i < lines.length; i++){
        let line = lines[i]
        if(line.length >= 1){
          let number = line.split(";")[0]
          let comment = line.split(";")[1]
          let date = line.split(";")[2]
          let d = date.split(".")[0]
          let m = date.split(".")[1] 
          let y = date.split(".")[2] 
          date = y+"-"+m+"-"+d
          date = new Date(date)
          let body = {
            created : now,
            creator : name,
            comment : comment,
            valid: true,
            validUntil : date.getTime(),
            phoneNumber: number
          }
          let asi = this.postLicense(body)
          if(asi){
            body.validuntil = body.validUntil
          
          this.addedLicenseNumbers.push(number)
          for(let i = 0; i < this.entries.length; i++) if(this.entries[i].cellnumber == number) this.entries[i].licenses.push(body)
          }
        }
      }
      let added = ""
      for(let i = 0; i < this.addedLicenseNumbers.length; i++) added += this.addedLicenseNumbers[i] + ", "
      added.slice(0, -2)
      alert("Licenses added to: "+added)
      this.licenseInfo = ""
      this.triggerAddLicense()
    },
    async postLicense(body) {
      let backendAPI = "http://tiw.traxby.com/api/rest/lic"
      axios.post(backendAPI, body).then((res) => {
        console.log(res)
        return true
      })
    },
    //OTHER FUNCTIONS
    toPortal() {
      let url = "https://portal.huntloc.com"
      const a = document.createElement("a")
      a.setAttribute("hidden", "")
      a.setAttribute("href", url)
      a.setAttribute("target", "_blank")
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    downloadCSV() {
        const rows = []
        const data = this.csvData
        const headers = ["name", "cellnumber", "country", "email", "created_date", "last_joined_date", "places", "created_hunts", "all_hunts", "deviceslength", "app", "phone", "licenseslength", "last_license_date"]
        rows.push(headers.join(";"))

        for (const row of data) {
          const values = headers.map((header) => {
            const escaped = ("" + row[header]).replace(/"/g, '\\"')
            if (`"${escaped}"` == '"null"') return '""'
            else return `"${escaped}"`
          })
          rows.push(values.join(";"))
        }
        let csv = rows.join("\n")

        const blob = new Blob([csv], { type: "text/csv" })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.setAttribute("hidden", "")
        a.setAttribute("href", url)
        a.setAttribute("download", "Users.csv")
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    },
    toggleDevices() {
      this.toggledevices = !this.toggledevices;
    },
    toggleLicenses() {
      this.togglelicenses = !this.togglelicenses;
    },
    toggleNotes() {
      this.togglenotes = !this.togglenotes;
    },
    resetUsers() {
      this.resetSortIcon();
      this.resetSortLogic;
      this.entries = this.defaultentries;
      this.filterentries = this.defaultentries;
      this.currentPage = 1;
      this.searchInput = "";
      (this.col = {
        name: "",
        cellnumber: "",
        country: "",
        email: "",
        created_date: "",
        last_joined_date: "",
        places: "",
        created_hunts: "",
        all_hunts: "",
        deviceslengthmin: "",
        deviceslengthmax: "",
        app: "",
        phone: "",
        licenseslength: "",
        last_license: "",
        noteslength: "",
      }),
        this.paginateEntries();
    },
    paginateEntries() {
      if (this.searchInput.length >= 1) {
        this.searchEntries = $.array.search(this.entries, this.searchInput);
        this.paginateData(this.searchEntries);
      } else {
        this.searchEntries = [];
        this.paginateData(this.entries);
      }
    },
    paginateEvent(page) {
      this.currentPage = page;
      this.paginateEntries();
    },
    searchEvent() {
      this.resetSortIcon();
      this.resetSortLogic;
      this.currentPage = 1;
      this.paginateEntries()
    },
    paginateData(data) {
      this.filteredEntries = $.array.paginate(
        data,
        this.currentPage,
        this.currentEntries
      );
      this.allPages = $.array.pages(data, this.currentEntries);
      this.csvData = this.filteredEntries
    },
    getCurrentEntries() {
      return this.searchEntries.length <= 0 ? this.entries : this.searchEntries;
    },
    filter(filterObj) {
      let filteredArray = Array.from(this.getCurrentEntries());
      for (let [key, val] of Object.entries(filterObj, "", 0)) {
        const filterfromArr = filteredArray.filter((obj) => {
          if (key in obj) {
            //filtering strings
            if (!key.includes("min") && !key.includes("max")) {
              if (obj[key] == Number(val)) {
                return true;
              } else if (
                obj[key] &&
                obj[key].toLowerCase().includes(val.toLowerCase())
              ) {
                return true;
              }
            }
            //filtering integers and dates
            else if (key.includes("min") || key.includes("max")) {
              //dates
              if (key.includes("date")) {
                if (val.length >= 4) {
                  let date, yyyy, valms, value;
                  if (val.includes("-")) {
                    valms = val.split("-");
                    yyyy = valms[0];
                    if (valms[1] != "" && valms[1] != null) {
                      let mm;
                      if (valms[1].length < 2) {
                        mm = "0" + valms[1];
                      } else {
                        mm = valms[1];
                      }
                      if (valms[2] != "" && valms[2] != null) {
                        let dd;
                        if (valms[2].length < 2) {
                          dd = "0" + valms[2];
                        } else {
                          dd = valms[2];
                        }
                        date = new Date([yyyy, mm, dd].join("-"));
                      } else {
                        date = new Date([yyyy, mm].join("-"));
                      }
                    } else {
                      date = new Date(yyyy);
                    }
                  } else {
                    yyyy = val;
                    date = new Date(yyyy);
                  }
                  let object = Number(obj[key]);
                  if (key.includes("min")) {
                    value = date.getTime() - 7200000;
                    if (object >= value) {
                      return true;
                    }
                  } else if (key.includes("max")) {
                    value = date.getTime() + 79199999;
                    if (object <= value) {
                      return true;
                    }
                  }
                }
              }
              //integers
              else {
                let object = Number(obj[key]);
                let value = Number(val);
                if (key.includes("min")) {
                  if (object >= value) {
                    return true;
                  }
                } else if (key.includes("max")) {
                  if (object <= value) {
                    return true;
                  }
                }
              }
            }
          }
          return false;
        });
        filteredArray = filterfromArr;
      }
      this.csvData = filteredArray
      console.log(this.csvData)
      return filteredArray;
    },
    filterByColumn() {
      this.resetSortIcon();
      this.currentPage = 1;
      this.entries = this.defaultentries;
      const filterCol = $.object.removeBy(this.col, "");
      let filterData = this.getCurrentEntries();

      if (Object.entries(filterCol).length >= 1) {
        filterData = this.filter(filterCol);
      }
      this.entries = filterData;
      this.filterentries = this.entries;
      this.resetSortLogic();
      this.paginateData(this.entries);
      this.allPages = $.array.pages(this.entries, this.currentEntries);
    },
    resetSortIcon() {
      //Resetting sorting icons
      (this.sortname = "default"),
        (this.sortnumber = "default"),
        (this.sortcountry = "default"),
        (this.sortemail = "default"),
        (this.sortcreated = "default"),
        (this.sortlastgroup = "default"),
        (this.sortplaces = "default"),
        (this.sortcreatedgroups = "default"),
        (this.sortallgroups = "default"),
        (this.sortdevices = "default"),
        (this.sortapp = "default"),
        (this.sortphone = "default"),
        (this.sortlicenses = "default"),
        (this.sortrecentlicense = "default"),
        (this.sortnotes = "default");
    },
    resetSortLogic() {
      //Resetting sorting logic to default
      this.sortcol["name"] = "";
      this.sortcol["cellnumber"] = "";
      this.sortcol["country"] = "";
      this.sortcol["email"] = "";
      this.sortcol["created_date"] = "";
      this.sortcol["last_joined_date"] = "";
      this.sortcol["places"] = "";
      this.sortcol["created_hunts"] = "";
      this.sortcol["all_hunts"] = "";
      this.sortcol["deviceslength"] = "";
      this.sortcol["app"] = "";
      this.sortcol["phone"] = "";
      this.sortcol["licenseslength"] = "";
      this.sortcol["last_license"] = "";
      this.sortcol["noteslength"] = "";
    },
    sortByColumn(column) {
      let sortedEntries = this.getCurrentEntries();
      let sortedColumn = this.sortcol[column];
      if (sortedColumn === "") {
        this.sortcol[column] = "asc";
        if (column == "name") {
          this.sortname = "asc";
        } else if (column == "cellnumber") {
          this.sortnumber = "asc";
        } else if (column == "country") {
          this.sortcountry = "asc";
        } else if (column == "email") {
          this.sortemail = "asc";
        } else if (column == "created_date") {
          this.sortcreated = "asc";
        } else if (column == "last_joined_date") {
          this.sortlastgroup = "asc";
        } else if (column == "places") {
          this.sortplaces = "asc";
        } else if (column == "created_hunts") {
          this.sortcreatedgroups = "asc";
        } else if (column == "all_hunts") {
          this.sortallgroups = "asc";
        } else if (column == "deviceslength") {
          this.sortdevices = "asc";
        } else if (column == "app") {
          this.sortapp = "asc";
        } else if (column == "phone") {
          this.sortphone = "asc";
        } else if (column == "licenseslength") {
          this.sortlicenses = "asc";
        } else if (column == "last_license") {
          this.sortrecentlicense = "asc";
        } else if (column == "noteslength") {
          this.sortnotes = "asc";
        }
        sortedEntries = $.array.sortBy(this.getCurrentEntries(), column, "asc");
        this.entries = sortedEntries;
      } else if (sortedColumn === "asc") {
        this.sortcol[column] = "desc";
        if (column == "name") {
          this.sortname = "desc";
        } else if (column == "cellnumber") {
          this.sortnumber = "desc";
        } else if (column == "country") {
          this.sortcountry = "desc";
        } else if (column == "email") {
          this.sortemail = "desc";
        } else if (column == "created_date") {
          this.sortcreated = "desc";
        } else if (column == "last_joined_date") {
          this.sortlastgroup = "desc";
        } else if (column == "places") {
          this.sortplaces = "desc";
        } else if (column == "created_hunts") {
          this.sortcreatedgroups = "desc";
        } else if (column == "all_hunts") {
          this.sortallgroups = "desc";
        } else if (column == "deviceslength") {
          this.sortdevices = "desc";
        } else if (column == "app") {
          this.sortapp = "desc";
        } else if (column == "phone") {
          this.sortphone = "desc";
        } else if (column == "licenseslength") {
          this.sortlicenses = "desc";
        } else if (column == "last_license") {
          this.sortrecentlicense = "desc";
        } else if (column == "noteslength") {
          this.sortnotes = "desc";
        }
        sortedEntries = $.array.sortBy(
          this.getCurrentEntries(),
          column,
          "desc"
        );
        this.entries = sortedEntries;
      } else if (sortedColumn === "desc") {
        this.sortcol[column] = "";
        if (column == "name") {
          this.sortname = "default";
        } else if (column == "cellnumber") {
          this.sortnumber = "default";
        } else if (column == "country") {
          this.sortcountry = "default";
        } else if (column == "email") {
          this.sortemail = "default";
        } else if (column == "created_date") {
          this.sortcreated = "default";
        } else if (column == "last_joined_date") {
          this.sortlastgroup = "default";
        } else if (column == "places") {
          this.sortplaces = "default";
        } else if (column == "created_hunts") {
          this.sortcreatedgroups = "default";
        } else if (column == "all_hunts") {
          this.sortallgroups = "default";
        } else if (column == "deviceslength") {
          this.sortdevices = "default";
        } else if (column == "app") {
          this.sortapp = "default";
        } else if (column == "phone") {
          this.sortphone = "default";
        } else if (column == "licenseslength") {
          this.sortlicenses = "default";
        } else if (column == "last_license") {
          this.sortrecentlicense = "default";
        } else if (column == "noteslength") {
          this.sortnotes = "default";
        }
        this.entries = this.filterentries;
      }
      this.paginateData(this.entries);
    },
  },
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}
html,
body {
  max-width: 100%;
}
th,
td,
tr,
select,
input,
body {
  font-size: 10px;
}
th {
  font-weight: normal;
}
button {
  font-size: 10px;
}
.invalidSoon {
  background-color: yellow;
}
.invalid {
  background-color: salmon;
}
.bi:hover {
  color: darkblue;
  cursor: pointer;
}
.search {
  font-weight: bold;
  color: orangered;
}
.form-search {
  background-color: salmon;
  font-weight: bold;
}
/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
.lds-ring-sm {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-ring-sm div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000 transparent transparent transparent;
}
.lds-ring-sm div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring-sm div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring-sm div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {
}
</style>

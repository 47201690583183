import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'alga-js'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap";
import firebase from 'firebase/app';
import "bootstrap-icons/font/bootstrap-icons.css";


const firebaseConfig = {
  apiKey: "AIzaSyBtFP1qU_kGa4Y1XPCMeBckJ5cuH9ZdDyU",
  authDomain: "hunter-server.firebaseapp.com",
  databaseURL: "https://hunter-server.firebaseio.com/",
  projectId: "hunter-server",
  storageBucket: "hunter-server.appspot.com",
  messagingSenderId: "495933178761",
  appId: "1:495933178761:web:37f4ecf92b1d9c4dcb5758",
  measurementId: "G-81BYX28WZH"
};

firebase.initializeApp(firebaseConfig)
firebase.auth().onAuthStateChanged(function(user) {
  if (user) {
    // User is signed in.
  } else {
    // No user is signed in.
  }
});

createApp(App).use(store).use(router).mount('#app');


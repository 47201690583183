<template>
  <Testing2 />
</template>

<script>
import Testing2 from '@/components/Testing2.vue'
  export default {
    name: 'Test',
    components: {
      Testing2,
    },
  }
</script>

<style lang="sass" scoped>
@media screen and (max-width: 1096px)
  
  #hometable
    max-width: 100%
  #mobilesidebar
    max-width: 100%
  
</style>
import { createRouter, createWebHashHistory } from 'vue-router'
import Sims from '../views/Sims.vue'
import Users from '../views/Users.vue'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import Test from '../views/Test.vue'
import Test2 from '../views/Test2.vue'
import Teams from '../views/Teams.vue'
import Employee from '../views/Employee.vue'
import news from '../views/news.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Sims',
    name: 'Sims',
    component: Sims
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
  },
  {
    path: '/Users',
    name: 'Users',
    component: Users
  },
  {
    path: '/Test',
    name: 'Test',
    component: Test
  },
  {
    path: '/Teams',
    name: 'Teams',
    component: Teams
  },
  {
    path: '/Employee',
    name: 'Employee',
    component: Employee
  },
  {
    path: '/Test2',
    name: 'Test2',
    component: Test2
  },
  {
    path: '/news',
    name: 'news',
    component: news
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router

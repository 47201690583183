<template>
<!--SEARCH-->
  <Modal v-if="popupTriggers.searchesButtonTrigger" :TogglePopup="() => TogglePopup('searchesButtonTrigger')">
    <p class="d-flex" style="font-weight: bold; font-size: 14px">Users <div v-if="refreshingData" class="d-flex">
            <div class="lds-ring-sm">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div></p>
    <table class="table table-bordered table-hover overflow-auto">
      <thead>
        <tr>
          <th>Name</th>
          <th>Cellnumber</th>
          <th>Country</th>
          <th>Email</th>
          <th>Created</th>
          <th>Last Group</th>
          <th>Places</th>
          <th>Created Groups</th>
          <th>All Groups</th>
          <th>Devices</th>
          <th>App</th>
          <th>Phone</th>
          <th>Licenses</th>
          <th>Recent License</th>
          <th>Notes</th>
          <th>Details</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="users in searchUsers" :key="users">
          <td>{{ users.name }}</td>
          <td>{{ users.cellnumber }}</td>
          <td>{{ users.country }}</td>
          <td>{{ users.email }}</td>
          <td>{{ users.created_date }}</td>
          <td>{{ users.last_joined_date }}</td>
          <td>{{ users.places }}</td>
          <td>{{ users.created_hunts }}</td>
          <td>{{ users.all_hunts }}</td>
          <td>{{ users.deviceslength }}</td>
          <td>{{ users.app }}</td>
          <td>{{ users.phone }}</td>
          <td>{{ users.licenseslength }}</td>
          <td>{{ users.last_license_date }}</td>
          <td>{{ users.noteslength }}</td>
          <td><svg v-on:click="usersModal(users)" xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/></svg></td>
        </tr>
      </tbody>
    </table>
    <p style="font-weight: bold; font-size: 14px">Sims</p>
    <table class="table table-bordered table-hover overflow-auto">
      <thead>
        <tr>
          <th>Number</th>
          <th>Valid Until</th>
          <th>Deleted</th>
          <th>Status</th>
          <th>Operator</th>
          <th>IMEI</th>
          <th>Brand</th>
          <th>Pricing Model</th>
          <th>Notes</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="sims in searchSims" :key="sims">
          <td>{{ sims.phoneNumber }}</td>
          <td>{{ sims.validUntil }}</td>
          <td>{{ sims.deleted }}</td>
          <td>{{ sims.status }}</td>
          <td>{{ sims.operator }}</td>
          <td>{{ sims.imei }}</td>
          <td>{{ sims.brand }}</td>
          <td>{{ sims.pricingModel }}</td>
          <td>{{ sims.notes }}</td>
        </tr>
      </tbody>
    </table>
  </Modal>
<!--USER-->
  <Modal v-if="popupTriggers.usersButtonTrigger" :TogglePopup="() => TogglePopup('usersButtonTrigger')">
    <div>
      <p style="font-weight: bold; font-size: 14px">General Info</p>
      <table class="table table-hover">
        <thead>
          <tr>
            <th class="bold">Name</th>
            <th class="bold">Cellnumber</th>
            <th class="bold">Email</th>
            <th class="bold">Created</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>{{ user.name }}</th>
            <th>{{ user.cellnumber }}</th>
            <th>{{ user.email }}</th>
            <th>{{ user.created_date }}</th>
          </tr>
        </tbody>
      </table>

      <p style="font-weight: bold; font-size: 14px">Teams Info</p>
      <table class="table table-hover">
        <thead>
          <tr>
            <th class="bold">Team name</th>
            <th class="bold">Role</th>
            <th class="bold">Team owner</th>
            <th class="bold">Team owner number</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in user.teamsinfo" :key="user">
            <th>{{ user.teamName }}</th>
            <th>{{ user.teamRole }}</th>
            <th>{{ user.teamOwner }}</th>
            <th>{{ user.teamOwnerNumber }}</th>
          </tr>
        </tbody>
      </table>

      <p style="font-weight: bold; font-size: 14px">Group Info</p>
      <table class="table table-hover">
        <thead>
          <tr>
            <th class="bold">Group</th>
            <th class="bold">Info</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th class="bold">Groups created</th>
            <th scope="row">
              {{ user.created_hunts }}
            </th>
          </tr>
          <tr>
            <th class="bold">Groups joined</th>
            <th scope="row">
              {{ user.all_hunts }}
            </th>
          </tr>
          <tr>
            <th class="bold">Last group</th>
            <th scope="row">
              <div v-if="user.last_joined_date == ''">None</div>
              <div v-else>
                {{ user.last_joined_date }}
              </div>
            </th>
          </tr>
        </tbody>
      </table>

      <div class="d-flex justify-content-between">
        <p style="font-weight: bold; font-size: 14px">Licenses</p>
        <button class="btn btn-outline-dark btn-rounded" v-on:click="toggleUserLicense()">Add License</button>
      </div>
      
      <div v-if="togglenewlicense">
        <p style="font-size: 10px; margin-bottom: -2px">Date</p>
        <input v-model="userLicenseDate" type="date" class="form-control" style="width:60%" />
        <p style="font-size: 10px; margin-bottom: -2px">Comment</p>
        <input id="newLicenseComment" type="text" class="form-control" style="width:60%" />
        <button v-on:click="submitSingleLicense(user)" class="btn btn-outline-dark btn-rounded mt-2">Add License</button>
      </div>
      
      <table class="table table-hover">
        <thead>
          <tr>
            <th class="bold">#</th>
            <th class="bold">Comment</th>
            <th class="bold">Creator</th>
            <th class="bold">Product</th>
            <th class="bold">Created</th>
            <th class="bold">Valid until</th>
          </tr>
        </thead>
        <tbody v-if="!togglelicenses && user.licenses != null">
          <tr>
            <th>1</th>
            <th>{{ user.licenses[0].comment }}</th>
            <th>{{ user.licenses[0].creator }}</th>
            <th>{{ user.licenses[0].product }}</th>
            <th>{{ new Date(user.licenses[0].created).getFullYear() }}-{{("0" + (new Date(user.licenses[0].created).getMonth() + 1)).slice(-2)}}-{{("0" + new Date(user.licenses[0].created).getDate()).slice(-2)}}</th>
            <th>{{ new Date(user.licenses[0].validuntil).getFullYear() }}-{{("0" + (new Date(user.licenses[0].validuntil).getMonth() + 1)).slice(-2)}}-{{("0" + new Date(user.licenses[0].validuntil).getDate()).slice(-2)}}</th>
          </tr>
        </tbody>
        <tbody v-else-if="togglelicenses">
          <tr v-for="(user, index) in user.licenses" :key="user">
            <th scope="row">{{ index + 1 }}</th>
            <th scope="row">{{ user.comment }}</th>
            <th scope="row">{{ user.creator }}</th>
            <th scope="row">{{ user.product }}</th>
            <th scope="row">{{ new Date(user.created).getFullYear() }}-{{("0" + (new Date(user.created).getMonth() + 1)).slice(-2)}}-{{ ("0" + new Date(user.created).getDate()).slice(-2) }}</th>
            <th scope="row">{{ new Date(user.validuntil).getFullYear() }}-{{("0" + (new Date(user.validuntil).getMonth() + 1)).slice(-2)}}-{{ ("0" + new Date(user.validuntil).getDate()).slice(-2) }}</th>
          </tr>
        </tbody>
      </table>

      <div class="row justify-content-center" v-if="!togglelicenses">
        <svg v-on:click="toggleLicenses()" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-expand" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8zM7.646.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 1.707V5.5a.5.5 0 0 1-1 0V1.707L6.354 2.854a.5.5 0 1 1-.708-.708l2-2zM8 10a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 14.293V10.5A.5.5 0 0 1 8 10z" /></svg>
      </div>
      <div class="row justify-content-center" v-else-if="togglelicenses">
        <svg v-on:click="toggleLicenses()" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-collapse" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8zm7-8a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 4.293V.5A.5.5 0 0 1 8 0zm-.5 11.707-1.146 1.147a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 11.707V15.5a.5.5 0 0 1-1 0v-3.793z" /></svg>
      </div>


      <p style="font-weight: bold; font-size: 14px">Devices</p>
      <table class="table table-hover">
        <thead>
          <tr>
            <th class="bold">#</th>
            <th class="bold">App Language</th>
            <th class="bold">App Version</th>
            <th class="bold">Created</th>
            <th class="bold">Email</th>
            <th class="bold">Manufacturer</th>
            <th class="bold">Model</th>
            <th class="bold">Name</th>
            <th class="bold">OS</th>
            <th class="bold">OS Version</th>
          </tr>
        </thead>
        <tbody v-if="!toggledevices && user.devices != null">
          <tr>
            <th>1</th>
            <th>{{ user.devices[0].applanguage }}</th>
            <th>{{ user.devices[0].appversion }}</th>
            <th>{{ new Date(user.devices[0].created).getFullYear() }}-{{("0" + (new Date(user.devices[0].created).getMonth() + 1)).slice(-2)}}-{{("0" + new Date(user.devices[0].created).getDate()).slice(-2)}}</th>
            <th>{{ user.devices[0].email }}</th>
            <th>{{ user.devices[0].manufacturer }}</th>
            <th>{{ user.devices[0].model }}</th>
            <th>{{ user.devices[0].name }}</th>
            <th>{{ user.devices[0].opsys }}</th>
            <th>{{ user.devices[0].opsysversion }}</th>
          </tr>
        </tbody>
        <tbody v-else-if="toggledevices">
          <tr v-for="(user, index) in user.devices" :key="user">
            <th scope="row">{{ index + 1 }}</th>
            <th scope="row">{{ user.applanguage }}</th>
            <th scope="row">{{ user.appversion }}</th>
            <th scope="row">{{ new Date(user.created).getFullYear() }}-{{("0" + (new Date(user.created).getMonth() + 1)).slice(-2)}}-{{("0" + new Date(user.created).getDate()).slice(-2)}}</th>
            <th scope="row">{{ user.email }}</th>
            <th scope="row">{{ user.manufacturer }}</th>
            <th scope="row">{{ user.model }}</th>
            <th scope="row">{{ user.name }}</th>
            <th scope="row">{{ user.opsys }}</th>
            <th scope="row">{{ user.opsysversion }}</th>
          </tr>
        </tbody>
      </table>

      <div class="row justify-content-center" v-if="!toggledevices">
        <svg v-on:click="toggleDevices()" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-expand" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8zM7.646.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 1.707V5.5a.5.5 0 0 1-1 0V1.707L6.354 2.854a.5.5 0 1 1-.708-.708l2-2zM8 10a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 14.293V10.5A.5.5 0 0 1 8 10z" /></svg>
      </div>
      <div class="row justify-content-center" v-else-if="toggledevices">
        <svg v-on:click="toggleDevices()" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-collapse" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8zm7-8a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 4.293V.5A.5.5 0 0 1 8 0zm-.5 11.707-1.146 1.147a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 11.707V15.5a.5.5 0 0 1-1 0v-3.793z" /></svg>
      </div>

      <p style="font-weight: bold; font-size: 14px">Notes</p>
      <table class="table table-hover">
        <thead>
          <tr>
            <th class="bold">#</th>
            <th class="bold">Created</th>
            <th class="bold">Comment</th>
          </tr>
        </thead>
        <tbody v-if="!togglenotes && user.notes.length != 0">
          <tr>
            <th>1</th>
            <th>{{ new Date(user.notes[0].created).getFullYear() }}-{{("0" + (new Date(user.notes[0].created).getMonth() + 1)).slice(-2)}}-{{("0" + new Date(user.notes[0].created).getDate()).slice(-2)}}</th>
            <th v-if="user.notes[0].data.text != null">{{ user.notes[0].data.text }}</th>
            <th scope="row" v-else-if="user.notes[0].data.text == null && user.notes[0].name == 'huntloc_login'">Huntloc Login</th>
            <th scope="row" v-else-if="user.notes[0].data.text == null && user.notes[0].name == 'liveteam_login'">Liveteam Login</th>
            <th scope="row" v-else></th>
          </tr>
        </tbody>
        <tbody v-else-if="togglenotes">
          <tr v-for="(notes, index) in user.notes" :key="notes">
            <th scope="row">{{ index + 1 }}</th>
            <th>{{ new Date(notes.created).getFullYear() }}-{{("0" + (new Date(notes.created).getMonth() + 1)).slice(-2)}}-{{("0" + new Date(notes.created).getDate()).slice(-2)}}</th>
            <th scope="row" v-if="notes.data.text != null">{{ notes.data.text }}</th>
            <th scope="row" v-else-if="notes.data.text == null && notes.name == 'huntloc_login'">Huntloc Login</th>
            <th scope="row" v-else-if="notes.data.text == null && notes.name == 'liveteam_login'">Liveteam Login</th>
            <th scope="row" v-else></th>
          </tr>
        </tbody>
      </table>

      <div class="row justify-content-center" v-if="!togglenotes">
        <svg v-on:click="toggleNotes()" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-expand" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8zM7.646.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 1.707V5.5a.5.5 0 0 1-1 0V1.707L6.354 2.854a.5.5 0 1 1-.708-.708l2-2zM8 10a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 0 1 .708-.708L7.5 14.293V10.5A.5.5 0 0 1 8 10z" /></svg>
      </div>
      <div class="row justify-content-center" v-else-if="togglenotes">
        <svg v-on:click="toggleNotes()" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrows-collapse" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8zm7-8a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 4.293V.5A.5.5 0 0 1 8 0zm-.5 11.707-1.146 1.147a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 11.707V15.5a.5.5 0 0 1-1 0v-3.793z" /></svg>
      </div>

      <p style="font-weight: bold; font-size: 14px">Add note</p>
      <textarea id="userNoteInput" style="height: 100px" class="form-control" />
      <button type="button" class="btn btn-outline-dark btn-rounded" data-mdb-ripple-color="dark" v-on:click="addNotes(user)">Add Note</button>

            <div class="justify-content-between d-flex"><span><p style="font-weight: bold; font-size: 14px">Portal Authentication</p></span><span><button class="btn btn-outline-dark btn-rounded" v-on:click="toPortal()">Portal</button></span></div>

      <input type="text" class="form-control" placeholder="" v-model="portalPIN"/>
      <button type="button" class="btn btn-outline-dark btn-rounded" data-mdb-ripple-color="dark" v-on:click="loginPortal(user)">Login</button>
    </div>
  </Modal>

<!--MAIN-->
  <div class="col bg-light" style="width: 100%" v-if="!store.state.usersLoaded && !store.state.localUsers">
    <div class="lds-ring" style="margin-left: 50%; margin-top: 20%">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <!--SEARCHESMODAL SHOWER-->
  <div v-else class="col bg-light">
    <div class="mt-3 d-flex justify-content-between">
      <div>
        <p class="me-3 d-flex">Last updated: {{ last_updated }}  <div v-if="refreshingData" class="d-flex">
            <div class="lds-ring-sm">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </p>
      </div>
      <div class="d-flex">
        <span v-if="loadingSearch">
          <div class="lds-ring-sm">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </span>
        <span><input class="form-control form-control-sm me-3 search d-flex flex-end" type="search" placeholder="Search.." v-model="searchInput" v-on:keydown.enter="searchFor()" aria-label="Search"/></span>
      </div>
    </div>
    <table class="table table-bordered table-hover">
      <thead style="border-top: 1px; border-bottom: 1px">
        <tr>
          <th class="alignright">New Users</th>
          <th>Today</th>
          <th>Yesterday</th>
          <th>Last 7 days</th>
          <th>Last 30 days</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th class="alignright">Total:</th>
          <th>{{ utoday }}</th>
          <th>{{ uyesterday }}</th>
          <th>{{ uweek }}</th>
          <th>{{ umonth }}</th>
        </tr>
        <tr>
          <th class="alignright">Estonia:</th>
          <th>{{ esttoday }}</th>
          <th>{{ estyesterday }}</th>
          <th>{{ estweek }}</th>
          <th>{{ estmonth }}</th>
        </tr>
        <tr>
          <th class="alignright">Latvia:</th>
          <th>{{ ltvtoday }}</th>
          <th>{{ ltvyesterday }}</th>
          <th>{{ ltvweek }}</th>
          <th>{{ ltvmonth }}</th>
        </tr>
        <tr>
          <th class="alignright">Lithuania:</th>
          <th>{{ littoday }}</th>
          <th>{{ lityesterday }}</th>
          <th>{{ litweek }}</th>
          <th>{{ litmonth }}</th>
        </tr>
        <tr>
          <th class="alignright">Finland:</th>
          <th>{{ fintoday }}</th>
          <th>{{ finyesterday }}</th>
          <th>{{ finweek }}</th>
          <th>{{ finmonth }}</th>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th class="alignright">Country Sum:</th>
          <th>{{ sumtoday }}</th>
          <th>
            {{ sumyest }}
          </th>
          <th>{{ sumweek }}</th>
          <th>{{ summonth }}</th>
        </tr>
      </tfoot>
    </table>

    <table class="table table-bordered table-hover mt-5">
      <thead style="border-top: 1px; border-bottom: 1px">
        <tr>
          <th class="alignright">Expiring Licenses</th>
          <th>Today</th>
          <th>Yesterday</th>
          <th>Last 7 days</th>
          <th>Last 30 days</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th class="alignright">Total:</th>
          <th>{{ exptoday }}</th>
          <th>{{ expyesterday }}</th>
          <th>{{ expweek }}</th>
          <th>{{ expmonth }}</th>
        </tr>
        <tr>
          <th class="alignright">Estonia:</th>
          <th>{{ estexptoday }}</th>
          <th>{{ estexpyesterday }}</th>
          <th>{{ estexpweek }}</th>
          <th>{{ estexpmonth }}</th>
        </tr>
        <tr>
          <th class="alignright">Latvia:</th>
          <th>{{ ltvexptoday }}</th>
          <th>{{ ltvexpyesterday }}</th>
          <th>{{ ltvexpweek }}</th>
          <th>{{ ltvexpmonth }}</th>
        </tr>
        <tr>
          <th class="alignright">Lithuania:</th>
          <th>{{ litexptoday }}</th>
          <th>{{ litexpyesterday }}</th>
          <th>{{ litexpweek }}</th>
          <th>{{ litexpmonth }}</th>
        </tr>
        <tr>
          <th class="alignright">Finland:</th>
          <th>{{ finexptoday }}</th>
          <th>{{ finexpyesterday }}</th>
          <th>{{ finexpweek }}</th>
          <th>{{ finexpmonth }}</th>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th class="alignright">Country Sum:</th>
          <th>{{ expsumtoday }}</th>
          <th>
            {{ expsumyest }}
          </th>
          <th>{{ expsumweek }}</th>
          <th>{{ expsummonth }}</th>
        </tr>
      </tfoot>
    </table>

    <table class="table table-bordered table-hover mt-5">
      <thead style="border-top: 1px; border-bottom: 1px">
        <tr>
          <th class="alignright">Groups Joined</th>
          <th>Today</th>
          <th>Yesterday</th>
          <th>Last 7 days</th>
          <th>Last 30 days</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th class="alignright">Total:</th>
          <th>{{ gtoday }}</th>
          <th>{{ gyesterday }}</th>
          <th>{{ gweek }}</th>
          <th>{{ gmonth }}</th>
        </tr>
        <tr>
          <th class="alignright">Estonia:</th>
          <th>{{ estgtoday }}</th>
          <th>{{ estgyesterday }}</th>
          <th>{{ estgweek }}</th>
          <th>{{ estgmonth }}</th>
        </tr>
        <tr>
          <th class="alignright">Latvia:</th>
          <th>{{ ltvgtoday }}</th>
          <th>{{ ltvgyesterday }}</th>
          <th>{{ ltvgweek }}</th>
          <th>{{ ltvgmonth }}</th>
        </tr>
        <tr>
          <th class="alignright">Lithuania:</th>
          <th>{{ litgtoday }}</th>
          <th>{{ litgyesterday }}</th>
          <th>{{ litgweek }}</th>
          <th>{{ litgmonth }}</th>
        </tr>
        <tr>
          <th class="alignright">Finland:</th>
          <th>{{ fingtoday }}</th>
          <th>{{ fingyesterday }}</th>
          <th>{{ fingweek }}</th>
          <th>{{ fingmonth }}</th>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th class="alignright">Country Sum:</th>
          <th>{{ gsumtoday }}</th>
          <th>
            {{ gsumyest }}
          </th>
          <th>{{ gsumweek }}</th>
          <th>{{ gsummonth }}</th>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import axios from "axios"
import * as $ from "alga-js"
import { ref } from "vue"
import Modal from "@/components/Modal.vue"
import { inject } from 'vue'
import { mapState } from 'vuex'
import firebase from 'firebase'


export default {
  name: "HomeTable",
  components: {
    Modal,
  },
  setup() {
    const store = inject('store')
    const popupTriggers = ref({
      searchesButtonTrigger: false,
      usersButtonTrigger: false,
      simsButtonTrigger: false,
    });

    const TogglePopup = (trigger) => {
      popupTriggers.value[trigger] = !popupTriggers.value[trigger];
    };
    return {
      Modal,
      popupTriggers,
      TogglePopup,
      store
    };
  },
  mounted() {
    if(!this.store.state.usersLoaded){
      this.$store.dispatch('loadUsers')
    }
  },
  data() {
    return {
      loadingSearch: false,
      togglenotes: false,
      togglelicenses: false,
      toggledevices: false,
      togglenewlicense: false,
      title: "Info",
      searchInput: "",
      utoday: 0,
      uyesterday: 0,
      uweek: 0,
      umonth: 0,
      esttoday: 0,
      estyesterday: 0,
      estweek: 0,
      estmonth: 0,
      ltvtoday: 0,
      ltvyesterday: 0,
      ltvweek: 0,
      ltvmonth: 0,
      littoday: 0,
      lityesterday: 0,
      litweek: 0,
      litmonth: 0,
      fintoday: 0,
      finyesterday: 0,
      finweek: 0,
      finmonth: 0,
      exptoday: 0,
      expyesterday: 0,
      expweek: 0,
      expmonth: 0,
      estexptoday: 0,
      estexpyesterday: 0,
      estexpweek: 0,
      estexpmonth: 0,
      ltvexptoday: 0,
      ltvexpyesterday: 0,
      ltvexpweek: 0,
      ltvexpmonth: 0,
      litexptoday: 0,
      litexpyesterday: 0,
      litexpweek: 0,
      litexpmonth: 0,
      finexptoday: 0,
      finexpyesterday: 0,
      finexpweek: 0,
      finexpmonth: 0,
      gtoday: 0,
      gyesterday: 0,
      gweek: 0,
      gmonth: 0,
      estgtoday: 0,
      estgyesterday: 0,
      estgweek: 0,
      estgmonth: 0,
      ltvgtoday: 0,
      ltvgyesterday: 0,
      ltvgweek: 0,
      ltvgmonth: 0,
      litgtoday: 0,
      litgyesterday: 0,
      litgweek: 0,
      litgmonth: 0,
      fingtoday: 0,
      fingyesterday: 0,
      fingweek: 0,
      fingmonth: 0,
      sumtoday: 0,
      sumyest: 0,
      sumweek: 0,
      summonth: 0,
      expsumtoday: 0,
      expsumyest: 0,
      expsumweek: 0,
      expsummonth: 0,
      gsumtoday: 0,
      gsumyest: 0,
      gsumweek: 0,
      gsummonth: 0,
      portalPIN: "",
      loaded: false,
      last_updated: 0,
      refreshingData: true,
      tempInput: ""
    };
  },
  computed: {
    tableColumns() {
      return this.columns
    },
    computed: mapState(['users']),
  },
  created() {
    this.getLocalData()
    this.getData()
  },
  methods: {
    //GET REQUESTS_______________________________________________________________________________________
    saveData() {
      localStorage.setItem('utoday', this.utoday)
      localStorage.setItem('uyesterday', this.uyesterday)
      localStorage.setItem('uweek', this.uweek)
      localStorage.setItem('umonth', this.umonth)
      localStorage.setItem('esttoday', this.esttoday)
      localStorage.setItem('estyesterday', this.estyesterday)
      localStorage.setItem('estweek', this.estweek)
      localStorage.setItem('estmonth', this.estmonth)
      localStorage.setItem('ltvtoday', this.ltvtoday)
      localStorage.setItem('ltvyesterday', this.ltvyesterday)
      localStorage.setItem('ltvweek', this.ltvweek)
      localStorage.setItem('ltvmonth', this.ltvmonth)
      localStorage.setItem('littoday', this.littoday)
      localStorage.setItem('lityesterday', this.lityesterday)
      localStorage.setItem('litweek', this.litweek)
      localStorage.setItem('litmonth', this.litmonth)
      localStorage.setItem('fintoday', this.fintoday)
      localStorage.setItem('finyesterday', this.finyesterday)
      localStorage.setItem('finweek', this.finweek)
      localStorage.setItem('finmonth', this.finmonth)
      localStorage.setItem('exptoday', this.exptoday)
      localStorage.setItem('expyesterday', this.expyesterday)
      localStorage.setItem('expweek', this.expweek)
      localStorage.setItem('expmonth', this.expmonth)
      localStorage.setItem('estexptoday', this.estexptoday)
      localStorage.setItem('estexpyesterday', this.estexpyesterday)
      localStorage.setItem('estexpweek', this.estexpweek)
      localStorage.setItem('estexpmonth', this.estexpmonth)
      localStorage.setItem('ltvexptoday', this.ltvexptoday)
      localStorage.setItem('ltvexpyesterday', this.ltvexpyesterday)
      localStorage.setItem('ltvexpweek', this.ltvexpweek)
      localStorage.setItem('ltvexpmonth', this.ltvexpmonth)
      localStorage.setItem('litexptoday', this.litexptoday)
      localStorage.setItem('litexpyesterday', this.litexpyesterday)
      localStorage.setItem('litexpweek', this.litexpweek)
      localStorage.setItem('litexpmonth', this.litexpmonth)
      localStorage.setItem('finexptoday', this.finexptoday)
      localStorage.setItem('finexpyesterday', this.finexpyesterday)
      localStorage.setItem('finexpweek', this.finexpweek)
      localStorage.setItem('finexpmonth', this.finexpmonth)
      localStorage.setItem('gtoday', this.gtoday)
      localStorage.setItem('gyesterday', this.gyesterday)
      localStorage.setItem('gweek', this.gweek)
      localStorage.setItem('gmonth', this.gmonth)
      localStorage.setItem('estgtoday', this.estgtoday)
      localStorage.setItem('estgyesterday', this.estgyesterday)
      localStorage.setItem('estgweek', this.estgweek)
      localStorage.setItem('estgmonth', this.estgmonth)
      localStorage.setItem('ltvgtoday', this.ltvgtoday)
      localStorage.setItem('ltvgyesterday', this.ltvgyesterday)
      localStorage.setItem('ltvgweek', this.ltvgweek)
      localStorage.setItem('ltvgmonth', this.ltvgmonth)
      localStorage.setItem('litgtoday', this.litgtoday)
      localStorage.setItem('litgyesterday', this.litgyesterday)
      localStorage.setItem('litgweek', this.litgweek)
      localStorage.setItem('litgmonth', this.litgmonth)
      localStorage.setItem('fingtoday', this.fingtoday)
      localStorage.setItem('fingyesterday', this.fingyesterday)
      localStorage.setItem('fingweek', this.fingweek)
      localStorage.setItem('fingmonth', this.fingmonth)
      localStorage.setItem('sumtoday', this.sumtoday)
      localStorage.setItem('sumyest', this.sumyest)
      localStorage.setItem('sumweek', this.sumweek)
      localStorage.setItem('summonth', this.summonth)
      localStorage.setItem('expsumtoday', this.expsumtoday)
      localStorage.setItem('expsumyest', this.expsumyest)
      localStorage.setItem('expsumweek', this.expsumweek)
      localStorage.setItem('expsummonth', this.expsummonth)
      localStorage.setItem('gsumtoday', this.gsumtoday)
      localStorage.setItem('gsumyest', this.gsumyest)
      localStorage.setItem('gsumweek', this.gsumweek)
      localStorage.setItem('gsummonth', this.gsummonth)
    },
    getLocalData() {
      this.utoday= localStorage.getItem('utoday')
      this.uyesterday= localStorage.getItem('uyesterday')
      this.uweek= localStorage.getItem('uweek')
      this.umonth= localStorage.getItem('umonth')
      this.esttoday= localStorage.getItem('esttoday')
      this.estyesterday= localStorage.getItem('estyesterday')
      this.estweek= localStorage.getItem('estweek')
      this.estmonth= localStorage.getItem('estmonth')
      this.ltvtoday= localStorage.getItem('ltvtoday')
      this.ltvyesterday= localStorage.getItem('ltvyesterday')
      this.ltvweek= localStorage.getItem('ltvweek')
      this.ltvmonth= localStorage.getItem('ltvmonth')
      this.littoday= localStorage.getItem('littoday')
      this.lityesterday= localStorage.getItem('lityesterday')
      this.litweek= localStorage.getItem('litweek')
      this.litmonth= localStorage.getItem('litmonth')
      this.fintoday= localStorage.getItem('fintoday')
      this.finyesterday= localStorage.getItem('finyesterday')
      this.finweek= localStorage.getItem('finweek')
      this.finmonth= localStorage.getItem('finmonth')
      this.exptoday= localStorage.getItem('exptoday')
      this.expyesterday= localStorage.getItem('expyesterday')
      this.expweek= localStorage.getItem('expweek')
      this.expmonth= localStorage.getItem('expmonth')
      this.estexptoday= localStorage.getItem('estexptoday')
      this.estexpyesterday= localStorage.getItem('estexpyesterday')
      this.estexpweek= localStorage.getItem('estexpweek')
      this.estexpmonth= localStorage.getItem('estexpmonth')
      this.ltvexptoday= localStorage.getItem('ltvexptoday')
      this.ltvexpyesterday= localStorage.getItem('ltvexpyesterday')
      this.ltvexpweek= localStorage.getItem('ltvexpweek')
      this.ltvexpmonth= localStorage.getItem('ltvexpmonth')
      this.litexptoday= localStorage.getItem('litexptoday')
      this.litexpyesterday= localStorage.getItem('litexpyesterday')
      this.litexpweek= localStorage.getItem('litexpweek')
      this.litexpmonth= localStorage.getItem('litexpmonth')
      this.finexptoday= localStorage.getItem('finexptoday')
      this.finexpyesterday= localStorage.getItem('finexpyesterday')
      this.finexpweek= localStorage.getItem('finexpweek')
      this.finexpmonth= localStorage.getItem('finexpmonth')
      this.gtoday= localStorage.getItem('gtoday')
      this.gyesterday= localStorage.getItem('gyesterday')
      this.gweek= localStorage.getItem('gweek')
      this.gmonth= localStorage.getItem('gmonth')
      this.estgtoday= localStorage.getItem('estgtoday')
      this.estgyesterday= localStorage.getItem('estgyesterday')
      this.estgweek= localStorage.getItem('estgweek')
      this.estgmonth= localStorage.getItem('estgmonth')
      this.ltvgtoday= localStorage.getItem('ltvgtoday')
      this.ltvgyesterday= localStorage.getItem('ltvgyesterday')
      this.ltvgweek= localStorage.getItem('ltvgweek')
      this.ltvgmonth= localStorage.getItem('ltvgmonth')
      this.litgtoday= localStorage.getItem('litgtoday')
      this.litgyesterday= localStorage.getItem('litgyesterday')
      this.litgweek= localStorage.getItem('litgweek')
      this.litgmonth= localStorage.getItem('litgmonth')
      this.fingtoday= localStorage.getItem('fingtoday')
      this.fingyesterday= localStorage.getItem('fingyesterday')
      this.fingweek= localStorage.getItem('fingweek')
      this.fingmonth= localStorage.getItem('fingmonth')
      this.last_updated = localStorage.getItem('last_updated')
      this.sumtoday= localStorage.getItem('sumtoday')
      this.sumyest= localStorage.getItem('sumyest')
      this.sumweek= localStorage.getItem('sumweek')
      this.summonth= localStorage.getItem('summonth')
      this.expsumtoday= localStorage.getItem('expsumtoday')
      this.expsumyest= localStorage.getItem('expsumyest')
      this.expsumweek= localStorage.getItem('expsumweek')
      this.expsummonth= localStorage.getItem('expsummonth')
      this.gsumtoday= localStorage.getItem('gsumtoday')
      this.gsumyest= localStorage.getItem('gsumyest')
      this.gsumweek= localStorage.getItem('gsumweek')
      this.gsummonth= localStorage.getItem('gsummonth')
      let storage = []
        for(let i = 0; i < 2002; i++){
          let user = JSON.parse(localStorage.getItem(i))
          storage.push(user)
        }
      this.users = storage
      let simsAPI = "http://tiw.traxby.com/api/sim/all"
      axios.get(simsAPI).then((res) => {
        this.sims = res.data
      })
      let teamsAPI = "http://tiw.traxby.com/api/team/list_all"
        axios.get(teamsAPI).then((data) => {
          this.team = data.data
          for (let i = 0; i < this.team.length; i++) {
            this.team[i].phoneNumber = ""
            this.team[i].ownerName = ""
            this.team[i].teamName = ""
            if (this.team[i].permissions.length != 0 || this.team[i].permissions.length != null) {
              for (let j = 0; j < this.team[i].permissions.length; j++) {
                if (this.team[i].permissions[j].permission == "owner") {
                  this.team[i].phoneNumber = this.team[i].permissions[j].user.phoneNumber
                  this.team[i].ownerName = this.team[i].permissions[j].user.name
                  break
                }
              }
              if (this.team[i].name != null) this.team[i].teamName = this.team[i].name
            }
          }
        })
    },
    async getData() {
      let now = new Date()
      let yyyy = now.getFullYear()
      let mm = now.getMonth()
      let dd = now.getDate()
      let date = new Date(yyyy, mm, dd)
      let daystart = date.getTime()
      let dayend = daystart + 86399999
      let yesterdayend = daystart - 1
      let yesterdaystart = daystart - 86399999
      let weekdaystart = daystart - 604800000
      let monthdaystart = daystart - 2629800000
      if (this.store.state.usersLoaded) {
        this.users = this.store.state.users
        this.utoday = 0
        this.uyesterday = 0
        this.uweek = 0
        this.umonth =0
        this.esttoday =0
        this.estyesterday =0
        this.estweek =0
        this.estmonth =0
        this.ltvtoday =0
        this.ltvyesterday =0
        this.ltvweek =0
        this.ltvmonth =0
        this.littoday =0
        this.lityesterday =0
        this.litweek =0
        this.litmonth =0
        this.fintoday =0
        this.finyesterday =0
        this.finweek =0
        this.finmonth =0
        this.exptoday =0
        this.expyesterday =0
        this.expweek =0
        this.expmonth =0
        this.estexptoday =0
        this.estexpyesterday =0
        this.estexpweek = 0
        this.estexpmonth =0
        this.ltvexptoday =0
        this.ltvexpyesterday =0
        this.ltvexpweek =0
        this.ltvexpmonth =0
        this.litexptoday =0
        this.litexpyesterday =0
        this.litexpweek =0
        this.litexpmonth =0
        this.finexptoday =0
        this.finexpyesterday =0
        this.finexpweek =0
        this.finexpmonth =0
        this.gtoday =0
        this.gyesterday =0
        this.gweek =0
        this.gmonth =0
        this.estgtoday =0
        this.estgyesterday =0
        this.estgweek =0
        this.estgmonth =0
        this.ltvgtoday =0
        this.ltvgyesterday =0
        this.ltvgweek =0
        this.ltvgmonth =0
        this.litgtoday =0
        this.litgyesterday =0
        this.litgweek =0
        this.litgmonth =0
        this.fingtoday =0
        this.fingyesterday =0
        this.fingweek =0
        this.fingmonth =0
        this.sumtoday= 0
        this.sumyest= 0
        this.sumweek=0
        this.summonth= 0
        this.expsumtoday= 0
        this.expsumyest= 0
        this.expsumweek=0
        this.expsummonth= 0
        this.gsumtoday= 0
        this.gsumyest= 0
        this.gsumweek=0
        this.gsummonth= 0
        for (let i = 0; i < this.users.length; i++) {
          //Created accounts today
          if (this.users[i].created >= daystart && this.users[i].created <= dayend) {
            this.utoday++
            if (this.users[i].cellnumber != null) {
              if (this.users[i].cellnumber.substring(0, 4) == "+372"){ this.esttoday++; this.sumtoday++;}
              else if (this.users[i].cellnumber.substring(0, 4) == "+371"){ this.ltvtoday++; this.sumtoday++;}
              else if (this.users[i].cellnumber.substring(0, 4) == "+370"){ this.littoday++; this.sumtoday++;}
              else if (this.users[i].cellnumber.substring(0, 4) == "+358"){ this.fintoday++; this.sumtoday++;}
            }
          }
          //Created accounts yesterday
          if (this.users[i].created >= yesterdaystart && this.users[i].created <= yesterdayend) {
            this.uyesterday++
            if (this.users[i].cellnumber != null) {
              if (this.users[i].cellnumber.substring(0, 4) == "+372"){ this.estyesterday++; this.sumyest++;}
              else if (this.users[i].cellnumber.substring(0, 4) == "+371"){this.ltvyesterday++; this.sumyest++;}
              else if (this.users[i].cellnumber.substring(0, 4) == "+370"){ this.lityesterday++; this.sumyest++;}
              else if (this.users[i].cellnumber.substring(0, 4) == "+358"){ this.finyesterday++; this.sumyest++;}
            }
          }
          //Created account this week
          if (this.users[i].created >= weekdaystart && this.users[i].created <= dayend) {
            this.uweek++
            if (this.users[i].cellnumber != null) {
              if (this.users[i].cellnumber.substring(0, 4) == "+372"){ this.estweek++; this.sumweek++;}
              else if (this.users[i].cellnumber.substring(0, 4) == "+371"){ this.ltvweek++; this.sumweek++;}
              else if (this.users[i].cellnumber.substring(0, 4) == "+370"){ this.litweek++; this.sumweek++;}
              else if (this.users[i].cellnumber.substring(0, 4) == "+358"){ this.finweek++; this.sumweek++;}
            }
          }
          //Created accounts this month
          if (this.users[i].created >= monthdaystart && this.users[i].created <= dayend) {
            this.umonth++
            if (this.users[i].cellnumber != null) {
              if (this.users[i].cellnumber.substring(0, 4) == "+372"){ this.estmonth++; this.summonth++;}
              else if (this.users[i].cellnumber.substring(0, 4) == "+371"){ this.ltvmonth++; this.summonth++;}
              else if (this.users[i].cellnumber.substring(0, 4) == "+370"){ this.litmonth++; this.summonth++;}
              else if (this.users[i].cellnumber.substring(0, 4) == "+358"){ this.finmonth++; this.summonth++;}
            }
          }
          //Expired licenses today
          if (this.users[i].licenses != null) {
            if (this.users[i].licenses[0].validuntil >= daystart && this.users[i].licenses[0].validuntil <= dayend) {
              this.exptoday++
              if (this.users[i].cellnumber != null) {
                if (this.users[i].cellnumber.substring(0, 4) == "+372") {this.estexptoday++; this.expsumtoday++;}
                else if (this.users[i].cellnumber.substring(0, 4) == "+371") {this.ltvexptoday++; this.expsumtoday++;}
                else if (this.users[i].cellnumber.substring(0, 4) == "+370") {this.litexptoday++; this.expsumtoday++;}
                else if (this.users[i].cellnumber.substring(0, 4) == "+358") {this.finexptoday++; this.expsumtoday++;}
              }
            }
            //Expired licenses yesterday
            if (this.users[i].licenses[0].validuntil >= yesterdaystart && this.users[i].licenses[0].validuntil <= yesterdayend) {
              this.expyesterday++
              if (this.users[i].cellnumber != null) {
                if (this.users[i].cellnumber.substring(0, 4) == "+372") {this.estexpyesterday++; this.expsumyest++;}
                else if (this.users[i].cellnumber.substring(0, 4) == "+371") {this.ltvexpyesterday++; this.expsumyest++;}
                else if (this.users[i].cellnumber.substring(0, 4) == "+370") {this.litexpyesterday++; this.expsumyest++;}
                else if (this.users[i].cellnumber.substring(0, 4) == "+358") {this.finexpyesterday++; this.expsumyest++;}
              }
            }
            //Expired licenses this week
            if (this.users[i].licenses[0].validuntil >= weekdaystart && this.users[i].licenses[0].validuntil <= dayend) {
              this.expweek++
              if (this.users[i].cellnumber != null) {
                if (this.users[i].cellnumber.substring(0, 4) == "+372") {this.estexpweek++; this.expsumweek++;}
                else if (this.users[i].cellnumber.substring(0, 4) == "+371") {this.ltvexpweek++; this.expsumweek++;}
                else if (this.users[i].cellnumber.substring(0, 4) == "+370") {this.litexpweek++; this.expsumweek++;}
                else if (this.users[i].cellnumber.substring(0, 4) == "+358") {this.finexpweek++; this.expsumweek++;}
              }
            }
            //Expired licenses this month
            if (this.users[i].licenses[0].validuntil >= monthdaystart && this.users[i].licenses[0].validuntil <= dayend) {
              this.expmonth++
              if (this.users[i].cellnumber != null) {
                if (this.users[i].cellnumber.substring(0, 4) == "+372") {this.estexpmonth++; this.expsummonth++;}
                else if (this.users[i].cellnumber.substring(0, 4) == "+371") {this.ltvexpmonth++; this.expsummonth++;}
                else if (this.users[i].cellnumber.substring(0, 4) == "+370") {this.litexpmonth++; this.expsummonth++;}
                else if (this.users[i].cellnumber.substring(0, 4) == "+358") {this.finexpmonth++; this.expsummonth++;}
              }
            }
          }
          //Groups joined today
          if (this.users[i].last_joined >= daystart && this.users[i].last_joined <= dayend) {
            this.gtoday++
            if (this.users[i].cellnumber != null) {
              if (this.users[i].cellnumber.substring(0, 4) == "+372") {this.estgtoday++; this.gsumtoday++;}
              else if (this.users[i].cellnumber.substring(0, 4) == "+371") {this.ltvgtoday++; this.gsumtoday++;}
              else if (this.users[i].cellnumber.substring(0, 4) == "+370") {this.litgtoday++; this.gsumtoday++;}
              else if (this.users[i].cellnumber.substring(0, 4) == "+358") {this.fingtoday++; this.gsumtoday++;}
            }
          }
          //Groups joined yesterday
          if (this.users[i].last_joined >= yesterdaystart && this.users[i].last_joined <= yesterdayend) {
            this.gyesterday++
            if (this.users[i].cellnumber != null) {
              if (this.users[i].cellnumber.substring(0, 4) == "+372") {this.estgyesterday++; this.gsumyest++;}
              else if (this.users[i].cellnumber.substring(0, 4) == "+371") {this.ltvgyesterday++; this.gsumyest++;}
              else if (this.users[i].cellnumber.substring(0, 4) == "+370") {this.litgyesterday++; this.gsumyest++;}
              else if (this.users[i].cellnumber.substring(0, 4) == "+358") {this.fingyesterday++; this.gsumyest++;}
            }
          }
          //Groups joined this week
          if (this.users[i].last_joined >= weekdaystart && this.users[i].last_joined <= dayend) {
            this.gweek++
            if (this.users[i].cellnumber != null) {
              if (this.users[i].cellnumber.substring(0, 4) == "+372") {this.estgweek++; this.gsumweek++;}
              else if (this.users[i].cellnumber.substring(0, 4) == "+371") {this.ltvgweek++; this.gsumweek++;}
              else if (this.users[i].cellnumber.substring(0, 4) == "+370") {this.litgweek++; this.gsumweek++;}
              else if (this.users[i].cellnumber.substring(0, 4) == "+358") {this.fingweek++; this.gsumweek++;}
            }
          }
          //Groups joined this month
          if (this.users[i].last_joined >= monthdaystart && this.users[i].last_joined <= dayend) {
            this.gmonth++
            if (this.users[i].cellnumber != null) {
              if (this.users[i].cellnumber.substring(0, 4) == "+372") {this.estgmonth++; this.gsummonth++;}
              else if (this.users[i].cellnumber.substring(0, 4) == "+371") {this.ltvgmonth++; this.gsummonth++;}
              else if (this.users[i].cellnumber.substring(0, 4) == "+370") {this.litgmonth++; this.gsummonth++;}
              else if (this.users[i].cellnumber.substring(0, 4) == "+358") {this.fingmonth++; this.gsummonth++;}
            }
          }
        }
        this.last_updated = localStorage.getItem('last_updated')
        this.loaded = true
        this.refreshingData = false
        if(this.loaded) this.saveData()
        let simsAPI = "http://tiw.traxby.com/api/sim/all"
        axios.get(simsAPI).then((res) => {
          this.sims = res.data
        })
        let teamsAPI = "http://tiw.traxby.com/api/team/list_all"
        axios.get(teamsAPI).then((data) => {
          this.team = data.data
          for (let i = 0; i < this.team.length; i++) {
            this.team[i].phoneNumber = ""
            this.team[i].ownerName = ""
            this.team[i].teamName = ""
            if (this.team[i].permissions.length != 0 || this.team[i].permissions.length != null) {
              for (let j = 0; j < this.team[i].permissions.length; j++) {
                if (this.team[i].permissions[j].permission == "owner") {
                  this.team[i].phoneNumber = this.team[i].permissions[j].user.phoneNumber
                  this.team[i].ownerName = this.team[i].permissions[j].user.name
                  break
                }
              }
              if (this.team[i].name != null) this.team[i].teamName = this.team[i].name
            }
          }
        })
        this.searchesModal()
        this.searchesModal()
      }
      else setTimeout(() => {  this.getData()  }, 10000)
    },
    //POST REQUESTS_________________________________________________________________________________________
    addNotes(user) {
      let note = document.getElementById("userNoteInput")
      if (note.value != "") {
        let body = {
          name: "admin_note",
          created: Date.now(),
          data: { text: note.value },
          dataJsonStr: null,
        }
        let apiURL = "http://tiw.traxby.com/api/activity/" + user.cellnumber
        axios.post(apiURL, body).then((res) => {
          console.log(res)
          alert("Note has been added")
          document.getElementById("userNoteInput").value = ""
          user.notes.push(body)

        })
      }
    },
    submitSingleLicense(user) {
      let now = Date.now()
      let email = firebase.auth().currentUser.email
      let name = email.split('@')[0].split('.')[0]
      name = name.charAt(0).toUpperCase() + name.slice(1)
      let comment = document.getElementById("newLicenseComment")
      let time = new Date(this.userLicenseDate)
      let body = {
        created : now,
        creator : name,
        comment : comment.value,
        valid : true,
        validUntil : time.getTime(),
        phoneNumber : user.cellnumber
      }
      console.log(body)
      let backendAPI = "http://tiw.traxby.com/api/rest/lic"
      axios.post(backendAPI, body).then((res) => {
        body.validuntil = body.validUntil
        console.log(res)
        user.licenses.push(body)
        alert("License added to "+user.cellnumber+" until "+this.userLicenseDate)
        this.togglenewlicense = !this.togglenewlicense
      })
    },
    toPortal() {
      let url = "https://portal.huntloc.com"
      const a = document.createElement("a")
      a.setAttribute("hidden", "")
      a.setAttribute("href", url)
      a.setAttribute("target", "_blank")
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    //TOGGLES_____________________________________________________________________________________
    toggleDevices() {
      this.toggledevices = !this.toggledevices
    },
    toggleLicenses() {
      this.togglelicenses = !this.togglelicenses
    },
    toggleNotes() {
      this.togglenotes = !this.togglenotes
    },
    toggleUserLicense() {
      this.togglenewlicense = !this.togglenewlicense
      let def = Date.now() + 31556952000
      let y = new Date(def).getFullYear()
      let m = ("0" + (new Date(def).getMonth() + 1)).slice(-2)
      let d = ("0" + (new Date(def).getDate())).slice(-2)
      this.userLicenseDate = y+"-"+m+"-"+d
    },
    //MODAL TRIGGERS______________________________________________________________________________
    searchFor() {
      if(this.searchInput.length > 0){
        this.loadingSearch = true
        setTimeout(() => {this.searchesModal()}, 1000)
      }
    },
    searchesModal(){
      if(this.tempInput != "" && this.searchInput == "") this.searchInput = this.tempInput
      this.searchEntries = $.array.search(this.users, this.searchInput)
      this.searchUsers = this.searchEntries
      this.searchEntries = $.array.search(this.sims, this.searchInput)
      this.searchSims = this.searchEntries
      this.TogglePopup("searchesButtonTrigger")
      this.loadingSearch = false
      if(this.refreshingData) this.tempInput = this.searchInput
      this.searchInput = ""
    },
    usersModal(user) {
      this.user = user
      let apiURL = "http://tiw.traxby.com/api/activity/" + user.cellnumber
      axios.get(apiURL).then((res) => {
        this.user.notes = res.data
        this.user.teamsinfo = []
        this.teamsinfo = []
          for(let i = 0; i < this.team.length; i++){
            for(let j = 0; j < this.team[i].permissions.length; j++){
              if(this.user.cellnumber == this.team[i].permissions[j].user.phoneNumber){
                let teamname = this.team[i].teamName
                let role = this.team[i].permissions[j].permission
                let name = this.team[i].ownerName
                let number = this.team[i].phoneNumber
                this.teamsinfo.push({
                  teamName: teamname,
                  teamRole: role,
                  teamOwner: name,
                  teamOwnerNumber: number
              })
              }
            }
          }
          this.user.teamsinfo = this.teamsinfo
          this.TogglePopup("usersButtonTrigger")
      })
    },
    simsModal(sim) {
      this.sim = sim
      this.TogglePopup("simsButtonTrigger")
    },
    //OTHER_____________________________________________________________________________________
    loginPortal(user) {
      let pin = this.portalPIN
      let cell = user.cellnumber
      let apiURL = "http://tiw.traxby.com/api/portal-auth/"+cell+"/"+pin
      axios.get(apiURL).then(res => {
        console.log(res)
        alert("User signed in")
        this.portalPIN = ""
      })
    },
  }
};
</script>

<style scoped>
.alignright {
  text-align: right;
  margin-right: 1px;
  width: 15%;
}
th,
td,
tr,
body,
select,
input,
button {
  font-size: 10px;
}
.search {
  font-weight: bold;
  color: orangered;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
.lds-ring-sm {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-ring-sm div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000 transparent transparent transparent;
}
.lds-ring-sm div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring-sm div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring-sm div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (max-width: 768px) {
  .home {
    max-width: 90%;
  }
}
</style>

<template>
  <div class="container-fluid">
    <div class="row mobile flex-nowrap">
      <MobileSideBar id="mobile" />
      <SideBar id="desktop" />
      <SimsTable id="simstable"/>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SimsTable from '@/components/SimsTable.vue'
import SideBar from '@/components/SideBar.vue'
import MobileSideBar from '@/components/MobileSideBar.vue'

export default {
  name: 'Sims',
  components: {
    SimsTable,
    SideBar,
    MobileSideBar
  }
}
</script>
<style scoped>
#mobile{
    display: none
  }
@media screen and (max-width: 768px){
  #mobile{
    display: flex;
  }
  #desktop{
    display: none
  }
  .mobile{
    display: grid
  }
}
</style>
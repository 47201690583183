<template>
  <div class="popup">
    <div class="popup-inner">
      <div style="float: right;"><button @click="TogglePopup()" class="btn btn-sm btn-outline-dark btn-rounded"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16"><path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z"/></svg></button></div>
      <div><slot /></div>
      <div>
    </div></div>
  </div>
</template>

<script>
export default {
  props: ['TogglePopup']
}
</script>

<style lang="scss">
.popup {
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgba(0,0,0,0.8);
  display: flex;
  
  justify-content: center;
}
button {
  font-size: 12px
}
.popup-inner {
    background-color: #fff;
    padding: 32px;
    max-height: 100%;
    overflow-y: auto
  }
</style>